import { TeamsOrganizationSingleResponse, TeamsOrganizationSummary, TeamsOrganizationsResponse } from '#types'

/**
 * Extract single Oura Teams organization summary item.
 *
 * @param singleResponse   Single Oura Teams organization summary response item
 */
export function extractSingleTeamsOrganizationSummaryFromResponse(
  singleResponse: TeamsOrganizationSingleResponse,
): TeamsOrganizationSummary {
  return <TeamsOrganizationSummary>{
    name: singleResponse.name,
    uid: singleResponse.uid,
    type: singleResponse.type,
    createdAt: singleResponse.createdAt,
    memberCount: singleResponse.memberCount,
    coachCount: singleResponse.coachCount,
    groupCount: singleResponse.groupCount,
    accountManagerEmail: singleResponse.accountManagerEmail ?? null,
    externalId: singleResponse.externalId ?? null,
    planName: singleResponse.planName,
    subscriptionStatus: singleResponse.subscriptionStatus ?? null,
    validTo: singleResponse.validTo ?? null,
  }
}

/**
 * Extract list of Oura Teams organization summary items from response
 *
 * @param teamsOrganizationsResponse   Teams organizations response
 */
export function extractTeamsOrganizationsFromResponse(
  teamsOrganizationsResponse: TeamsOrganizationsResponse,
): TeamsOrganizationSummary[] {
  const teamsOrganizations: TeamsOrganizationSummary[] = []
  for (const item of teamsOrganizationsResponse.teams) {
    teamsOrganizations.push(extractSingleTeamsOrganizationSummaryFromResponse(item))
  }
  return teamsOrganizations
}
