import { Component, Vue } from 'vue-facing-decorator'

import { AppStore, UserStore } from '#stores'

@Component
export class ZendeskTicketChecker extends Vue {
  public userStore = new UserStore()
  public appStore = new AppStore()

  public reason = ''

  public zdTicketNotFound = false

  public get userOpenZDTickets() {
    return this.userStore.openUserZdTickets
  }
  public get userAccessLevels() {
    return this.userStore.userAccessLevels
  }

  public async getUsersTicketStatus(uuid: string) {
    const appEnvs = ['local', 'development', 'testing', 'staging']
    const cloudEnvs = ['test', 'stage']

    if (appEnvs.includes(import.meta.env.VITE_APP_ENV)) {
      return { reason: `${import.meta.env.VITE_APP_ENV} app environment` }
    } else if (cloudEnvs.includes(this.appStore.cloudEnv)) {
      return { reason: `${import.meta.env.VITE_APP_ENV} cloud environment` }
    } else {
      await this.userStore.getUserZDTickets(uuid)
      const hasUserAccess = this.userAccessLevels && Object.values(this.userAccessLevels).some((value) => value)

      if (!this.userOpenZDTickets?.count && !hasUserAccess) {
        return { zdTicketNotFound: true }
      } else {
        this.reason = this.userOpenZDTickets?.count
          ? 'Open Zendesk ticket found'
          : `Has access: ${JSON.stringify(this.userAccessLevels)}`

        return { reason: this.reason }
      }
    }
  }
}
