<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-card min-height="600" :loading="movingPrepaid">
    <v-card-title>Move prepaid period</v-card-title>
    <v-card-subtitle>Moves prepaid period from one account to another</v-card-subtitle>

    <v-card-text>
      <v-progress-circular
        v-show="movingPrepaid"
        indeterminate
        size="70"
        width="8"
        style="position: absolute; top: 50%; left: 45%; z-index: 100"
      />
      <v-row ref="notification">
        <div v-if="notificationMessage" style="position: sticky; top: 60px; width: 100%">
          <v-sheet min-height="50">
            <v-alert v-if="notificationMessage" :type="notificationType">
              <div>
                {{ notificationMessage }}
              </div>
            </v-alert>
          </v-sheet>
        </div>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model.trim="search"
            label="Search for a target member"
            autofocus
            :error-messages="targetMemberSearchError"
            :disabled="loadingTargetMembers || movingPrepaid"
            @keydown="searchKeyPress"
            @keydown.space.prevent
          />
        </v-col>
        <v-col cols="2">
          <v-btn
            size="large"
            color="primary"
            variant="elevated"
            class="col-2 mt-2 ml-1 success"
            :disabled="loadingTargetMembers || movingPrepaid"
            @click="getTargetMembersList"
          >
            <span v-if="!loadingTargetMembers">GET</span>
            <v-progress-circular v-else indeterminate color="green" :size="20" />
          </v-btn>
        </v-col>
      </v-row>
      <v-fade-transition>
        <v-list v-if="targetMembers">
          <v-list-item
            v-for="member in targetMembers"
            :key="member.uuid"
            :hidden="selectedMember !== null && selectedMember !== member"
            :append-icon="selectedMember === member ? 'mdi-check' : undefined"
            :disabled="movingPrepaid"
            @click="selectUser(member)"
          >
            <v-list-item-title>{{ member.email }}</v-list-item-title>
            <v-list-item-title>{{ member.uuid }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-fade-transition>
      <v-checkbox
        v-if="selectedMember"
        v-model="moveToCurrentBillingPeriod"
        :disabled="movingPrepaid"
        label="Move to current billing period"
      />
    </v-card-text>
    <v-card-item>
      <v-alert v-if="selectedMember" type="warning">
        <p>
          Moving prepaid period from member
          <b>{{ currentMember?.email ? currentMember.email : currentMember?.uuid }}</b>
          to
          <b>{{ selectedMember?.email ? selectedMember.email : selectedMember?.uuid }}</b>
          .
        </p>
        <p class="my-5">
          <b>This action cannot be undone.</b>
        </p>
        <p>Are you sure you want to proceed?</p>

        <v-checkbox
          v-if="selectedMember"
          v-model="confirmationCheckbox"
          class="ml-n2"
          label="I understand and want to proceed"
          :disabled="movingPrepaid"
        />
      </v-alert>
    </v-card-item>
    <v-card-actions class="justify-space-between">
      <v-btn-group>
        <v-btn color="primary" variant="text" @click="back()">Back</v-btn>
      </v-btn-group>
      <v-btn-group>
        <v-btn color="primary" variant="text" @click="close()">Close</v-btn>
        <v-btn color="primary" variant="text" :disabled="confirmDisabled" @click="movePrepaid">Confirm</v-btn>
      </v-btn-group>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore, PrepaidStore, SubscriptionStore, UserStore } from '#stores'

  import { PrepaidMoveActionData, PrepaidMoveTargetMember } from '#types/prepaid'

  @Component
  export class PrepaidMove extends Vue {
    public userStore = new UserStore()
    public appStore = new AppStore()
    public prepaidStore = new PrepaidStore()
    public subscriptionStore = new SubscriptionStore()
    public notificationMessage: string | null = null
    public notificationType: 'warning' | 'error' | 'success' | 'info' = 'info'

    public search = ''

    public selectedMember: PrepaidMoveTargetMember | null = null
    public moveToCurrentBillingPeriod: boolean = true
    public confirmationCheckbox = false
    private justSaved = false

    private get rights() {
      return this.appStore.activeRights
    }

    public get targetMembers() {
      return this.prepaidStore.targetMemberSearchResults?.filter((member) => member.uuid !== this.currentMember?.uuid)
    }

    public get targetMemberSearchError() {
      return this.prepaidStore.targetMemberSearchError
    }

    public get loadingTargetMembers() {
      return this.prepaidStore.loadingTargetMembers
    }

    public get movingPrepaid() {
      return this.prepaidStore.movingPrepaidPlan
    }

    public get currentMember() {
      return this.userStore.user
    }

    public get confirmDisabled() {
      return !this.selectedMember || this.movingPrepaid || this.justSaved || !this.confirmationCheckbox
    }

    @Watch('targetMembers')
    public onTargetMembersChanged() {
      this.selectedMember = null
      if (this.targetMembers && this.targetMembers.length === 1) {
        this.selectedMember = this.targetMembers[0]
      }
    }

    public async getTargetMembersList() {
      await this.prepaidStore.getTargetMemberSearchResults(this.search, this.rights)
    }

    public searchKeyPress(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.getTargetMembersList()
      }
    }

    public selectUser(member: PrepaidMoveTargetMember) {
      this.selectedMember = this.selectedMember === member ? null : member
    }

    public async movePrepaid() {
      if (this.currentMember && this.selectedMember) {
        const payload: PrepaidMoveActionData = {
          targetAccountId: this.selectedMember.uuid,
          moveToCurrentBillingPeriod: this.moveToCurrentBillingPeriod,
        }
        await this.prepaidStore.movePrepaidPeriod(this.currentMember?.uuid, payload)
        const requestError = this.prepaidStore.prepaidMoveError

        if (requestError) {
          this.notificationType = 'error'
          this.notificationMessage = requestError?.userMessage
            ? requestError.userMessage
            : 'Failed to move prepaid period'
        } else {
          this.justSaved = true
          this.notificationType = 'success'
          this.notificationMessage = 'Prepaid period moved successfully'
          await this.subscriptionStore.getSubscriptions({ initial: false, userId: this.currentMember.uuid })
        }
      }
    }

    public created() {
      this.reset()
    }

    private reset() {
      this.selectedMember = null
      this.moveToCurrentBillingPeriod = true
      this.prepaidStore.$reset()
      this.justSaved = false
    }

    public close() {
      this.reset()
      this.$emit('close')
    }

    public back() {
      this.reset()
      this.$emit('back')
    }
  }

  export default toNative(PrepaidMove)
</script>
