import { UserStore } from './user'

import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { extractPrepaidMoveTargetMemberSearchResult } from '#utils/prepaid/extract'
import { UserSearch, isSearchError } from '#utils/user/search'

import { BaseStore } from '#stores/base'

import {
  PrepaidApplyActionData,
  PrepaidMoveActionData,
  PrepaidMoveTargetMember,
  PrepaidRemoveActionData,
} from '#types/prepaid'

// TODO: Remove underscore prefixes when taken into use
const REQUEST_SOURCE_APPLY_PREPAID = 'applyPrepaid'
const REQUEST_SOURCE_REMOVE_PREPAID = 'removePrepaid'
const REQUEST_SOURCE_MOVE_PREPAID = 'movePrepaid'
const REQUEST_SOURCE_SEARCH_TARGET_MEMBERS = 'targetMemberSearch'

const PREPAID_MOVE_TARGET_USER_SEARCH_LIMIT = 5

@Store()
export class PrepaidStore extends BaseStore {
  public targetMemberSearchResults: PrepaidMoveTargetMember[] | null = null
  public targetMemberSearchError: string | null = null

  public get loadingTargetMembers() {
    return this.waitingForData([REQUEST_SOURCE_SEARCH_TARGET_MEMBERS])
  }

  public get applyingPrepaidPlan(): boolean {
    return this.waitingForData([REQUEST_SOURCE_APPLY_PREPAID])
  }

  public get movingPrepaidPlan() {
    return this.waitingForData([REQUEST_SOURCE_MOVE_PREPAID])
  }

  public get removingPrepaidPlan() {
    return this.waitingForData([REQUEST_SOURCE_REMOVE_PREPAID])
  }

  public get prepaidMoveError() {
    return this.getRequestError(REQUEST_SOURCE_MOVE_PREPAID)
  }

  public get prepaidRemoveError() {
    return this.getRequestError(REQUEST_SOURCE_REMOVE_PREPAID)
  }

  public get prepaidApplyError() {
    return this.getRequestError(REQUEST_SOURCE_APPLY_PREPAID)
  }

  public resetPrepaidMoveError() {
    return this.resetRequestError(REQUEST_SOURCE_MOVE_PREPAID)
  }

  public async getTargetMemberSearchResults(search: string, rights: string[]) {
    const userStore = new UserStore()
    const userSearch = new UserSearch()

    this.updateDataWait({ source: REQUEST_SOURCE_SEARCH_TARGET_MEMBERS, wait: true })
    this.targetMemberSearchResults = null

    const searchConfig = userSearch.getSearchOptions(search, rights)

    if (isSearchError(searchConfig)) {
      this.targetMemberSearchError = searchConfig.errorMessage
      this.updateDataWait({ source: REQUEST_SOURCE_SEARCH_TARGET_MEMBERS, wait: false })

      return
    }

    const payload = { search: searchConfig.keyword, searchType: searchConfig.searchType }

    const result = await userStore.search(payload)

    if (result?.count === 0) {
      this.targetMemberSearchResults = null
      this.targetMemberSearchError = 'No members found'
      this.updateDataWait({ source: REQUEST_SOURCE_SEARCH_TARGET_MEMBERS, wait: false })

      return
    }

    if (result?.contents) {
      this.targetMemberSearchResults = extractPrepaidMoveTargetMemberSearchResult(
        result?.contents.slice(0, PREPAID_MOVE_TARGET_USER_SEARCH_LIMIT),
      )
      this.targetMemberSearchError = null
    }
    this.updateDataWait({ source: REQUEST_SOURCE_SEARCH_TARGET_MEMBERS, wait: false })
  }

  public async movePrepaidPeriod(sourceAccountUuid: string, data: PrepaidMoveActionData) {
    this.resetPrepaidMoveError()

    await this.makeRequest(
      { method: 'put', url: `/api/v1/users/${sourceAccountUuid}/subscription/prepaid-plan`, data: data },
      REQUEST_SOURCE_MOVE_PREPAID,
    )
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        this.handleRequestError(error, REQUEST_SOURCE_MOVE_PREPAID)
      })
  }

  public async removePrepaid(data: PrepaidRemoveActionData) {
    const userStore = new UserStore()
    this.resetRequestError(REQUEST_SOURCE_REMOVE_PREPAID)
    await this.makeRequest(
      {
        method: 'delete',
        url: `/api/v1/users/${data.memberId}/subscription/prepaid-plan`,
        params: { forceRemove: data.forceRemove },
      },
      REQUEST_SOURCE_REMOVE_PREPAID,
      data.memberId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_REMOVE_PREPAID)
    })
  }

  public async applyPrepaid(payload: PrepaidApplyActionData) {
    const userStore = new UserStore()
    this.resetRequestError(REQUEST_SOURCE_APPLY_PREPAID)

    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${payload.memberId}/subscription/prepaid-plan`,
        data: {
          applyToCurrentBillingPeriod: payload.applyToCurrentBillingPeriod,
          lengthInMonths: payload.lengthInMonths,
          reason: payload.reason,
        },
      },
      REQUEST_SOURCE_APPLY_PREPAID,
      payload.memberId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_APPLY_PREPAID)
    })
  }
}
