import { AxiosError } from 'axios'

import { startCase } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  SAMLAuthProvider,
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

import * as Sentry from '@sentry/vue'

let logoutTimeout: number | null = null

let authStateUnsubscribe: Unsubscribe | null = null

@Store()
export class AppStore extends Pinia {
  public user: any

  public claims: any

  public cloudEnv = ''

  public currentRoute = ''
  public previousRoute = ''

  public groups: string[] = []
  public rights: string[] = []

  public consent: any = null
  public accounts: any[] = []

  public overrides: string[] = []

  public autoLoginDisabled = false

  public sessionExpirationTime: null | number = null

  public get userEmail() {
    return this.user?.email || ''
  }

  public get activeRights() {
    return this.rights.filter((right) => !this.overrides.includes(right))
  }

  /*
   * Waits for user authentication.
   */
  public auth() {
    return new Promise((resolve) => {
      if (authStateUnsubscribe) {
        authStateUnsubscribe()
      }

      authStateUnsubscribe = onAuthStateChanged(getAuth(), async (user) => {
        const currentUser = this.user

        if (user) {
          if (currentUser?.id !== user.uid) {
            await this.getCurrentUser(user)

            this.updateFirebaseUser(user)
          }
        } else {
          // Ensure user is logged out if auth state changes

          await this.logout(window.location.pathname !== '/')

          // Auto login if in none root route path

          if (window.location.pathname !== '/') {
            await this.login()
          }
        }

        resolve(user)
      })
    })
  }

  /*
   * Performs user login procedure.
   */
  public async login(usePopup?: boolean) {
    const env = import.meta.env.VITE_APP_ENV

    const provider = new SAMLAuthProvider('saml.oura')

    if (usePopup || !this.autoLoginDisabled) {
      if (!usePopup && env === 'release') {
        signInWithRedirect(getAuth(), provider)
      } else {
        try {
          await signInWithPopup(getAuth(), provider)

          window.location.reload()
        } catch (_error) {
          console.info('Popup closed or blocked, login interrupted')

          if (!usePopup && !this.autoLoginDisabled) {
            this.resetAppUrlRouteAndParams()
          }
        }
      }
    }
  }

  /*
   * Performs user logout with re-login.
   */
  public async logout(reLogin?: boolean) {
    // This initiates the firebase logout and resets state

    this.user = reLogin && !this.autoLoginDisabled ? undefined : null

    if (!reLogin && !this.autoLoginDisabled) {
      this.resetAppUrlRouteAndParams()
    }

    signOut(getAuth())
  }

  /*
   * Toggles given user right in overrides.
   */

  public setOverrides(overrides: any) {
    this.overrides = overrides

    sessionStorage.OuraOverrides = this.overrides.join(' ')
  }

  public toggleRights(right: string) {
    if (this.overrides.includes(right)) {
      sessionStorage.OuraOverrides = this.overrides.filter((override) => override !== right).join(' ')
    } else {
      sessionStorage.OuraOverrides = [...this.overrides, right].join(' ')
    }

    console.log('Overrides changed', this.overrides)

    window.location.reload()
  }

  /*
   * Sets and stores the selected cloud env.
   */
  public async setCloudEnv(env: string) {
    const curEnv = this.cloudEnv

    this.cloudEnv = env || sessionStorage.OuraCloudEnv

    sessionStorage.OuraCloudEnv = env || sessionStorage.OuraCloudEnv

    if (curEnv !== this.cloudEnv) {
      await this.$router.isReady()

      // This is to reset any data loading done by the pages

      if (this.$router.currentRoute.value.query.env !== env) {
        this.$router.push({
          path: '/',
          query: {
            ...this.$router.currentRoute.value.query,
            env: this.cloudEnv,
          },
        })
      }
    } else {
      // This is to update the support accounts when the page is not refreshed
      await this.getOuraAccounts()
    }
  }

  /*
   * Gets current user rights info from the backend.
   */
  private async getCurrentUser(user: any) {
    if (sessionStorage.OuraOverrides) {
      this.overrides = sessionStorage.OuraOverrides.split(' ')
    }

    const response = await this.$axios
      .get(`/api/v1/me`, {
        apiEnv: sessionStorage.OuraCloudEnv,
      })
      .catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })

    console.log('User info', response?.data)

    await user?.getIdTokenResult(true).then(async (result: any) => {
      console.log('Auth info', result?.claims)

      this.claims = result?.claims || {}

      if (this.claims?.firebase?.sign_in_attributes?.groups) {
        const groups = Array.isArray(this.claims?.firebase?.sign_in_attributes?.groups)
          ? this.claims?.firebase?.sign_in_attributes?.groups
          : [this.claims?.firebase?.sign_in_attributes?.groups]

        this.groups = groups.map((g: string) => 'group' + startCase(g).replace(/ /g, ''))
      }

      if (result?.claims?.auth_time) {
        this.setSessionExpirationTime(+result.claims.auth_time * 1000)
      }
    })

    this.user = typeof response?.data === 'object' ? response?.data : null

    if (import.meta.env.VITE_LOCAL_RIGHTS && window.location.host.startsWith('localhost')) {
      this.rights = import.meta.env.VITE_LOCAL_RIGHTS.split(',')
    } else {
      this.rights = response?.data?.rights || []
    }

    Sentry.setUser({ email: response?.data?.email || '' })
  }

  /*
   * Adds Oura account for the current user.
   */
  public async addOuraAccount(email: string) {
    const response = await this.$axios
      .post(
        '/api/v1/me/access-rights',
        {
          email,
          consentContentUid: this.consent?.consentContentUid,
        },
        { apiEnv: sessionStorage.OuraCloudEnv },
      )
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })

    return response
  }

  /*
   * Gets users Oura accounts from the backend.
   */
  public async getOuraAccounts() {
    const response = await this.$axios
      .get('/api/v1/me/access-rights', {
        apiEnv: sessionStorage.OuraCloudEnv,
      })
      .catch((_axiosError: AxiosError) => {
        //ignore for now
        return null
      })

    this.accounts = response?.data?.accessRights || []
    this.consent = response?.data?.consentContent || null
  }

  /*
   * Updates user login info into firestore database.
   */
  private async updateFirebaseUser(user: any) {
    const docRef = doc(getFirestore(), 'users', `${user.uid}`)

    await setDoc(
      docRef,
      {
        email: user.email,
        groups: this.groups,
        rights: this.rights,
        lastActive: serverTimestamp(),
      },
      { merge: true },
    )
  }

  /*
   * Sets login session expiration time for 12 hours.
   */
  private setSessionExpirationTime(authTime?: number) {
    if (logoutTimeout) {
      window.clearTimeout(logoutTimeout)
    }

    const sessionDuration = 12 * 60 * 60 * 1000

    const millisecondsUntilExpiration = sessionDuration - (Date.now() - (authTime || 0))

    if (millisecondsUntilExpiration <= 0) {
      this.sessionExpirationTime = null

      this.logout()
    } else {
      this.sessionExpirationTime = new Date((authTime || 0) + sessionDuration).getTime()

      logoutTimeout = window.setTimeout(() => this.logout(), millisecondsUntilExpiration)
    }
  }

  /*
   * Resets all stored route params on session logout.
   */
  private resetAppUrlRouteAndParams(forcePathReset?: boolean) {
    sessionStorage.removeItem('OuraRouteParams')

    if (forcePathReset || window.location.pathname !== '/') {
      window.location.href = '/'
    }
  }
}
