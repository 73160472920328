import { formatDateTime, formatDateTimeWithDisplayTimezone, getTimezoneUTCOffset } from '#utils/datetime/format'

import {
  DisplayTimezone,
  Nullable,
  SummaryDataReportItem,
  SummaryDataResponse,
  SummaryDataSingleResponse,
} from '#types'

/**
 * Extract single summary data report item from summary data response
 *
 * @param singleResponse   Single summary data report item in response
 * @param displayTimezone  Display dates and times in certain timezone
 */
export function extractSingleSummaryDataReportItemFromResponse(
  singleResponse: SummaryDataSingleResponse,
  displayTimezone: DisplayTimezone = DisplayTimezone.MEMBER,
): SummaryDataReportItem {
  const timezone: Nullable<string> =
    singleResponse.timezone !== null ? getTimezoneUTCOffset(singleResponse.timezone) : null
  const bedtimeStart = formatDateTimeWithDisplayTimezone(singleResponse.bedtimeStart, displayTimezone, 'HH:mm')
  const bedtimeEnd = formatDateTimeWithDisplayTimezone(singleResponse.bedtimeEnd, displayTimezone, 'HH:mm')

  let temperature: Nullable<number> = null
  if (singleResponse.highestTemperature) {
    temperature = Number.parseFloat(Number.parseFloat(String(singleResponse.highestTemperature)).toFixed(2))
  }

  let nonWearMinutes: Nullable<number> = null
  const events = singleResponse.events ?? []
  if (singleResponse.nonWearTime && singleResponse.nonWearTime > 0) {
    nonWearMinutes = Math.round(singleResponse.nonWearTime / 60)
    if (singleResponse.nonWearTime === 86400) {
      events.push('Non-Wear Day')
    }
  }

  let nonWearMinutesDuringBedtime: Nullable<number> = null
  if (singleResponse.nonWearTimeDuringBedtime && singleResponse.nonWearTimeDuringBedtime > 0) {
    nonWearMinutesDuringBedtime = Math.round(singleResponse.nonWearTimeDuringBedtime / 60)
  }

  const isExpandable =
    singleResponse.events?.length || singleResponse.selfTestDetails?.length || singleResponse.fieldTestDetails?.length

  const selfTestDetails = singleResponse.selfTestDetails
    ? singleResponse.selfTestDetails.map((message) => message.replace(' self-test', ''))
    : []

  return {
    day: singleResponse.day, // date in YYYY-MM-DD is used for UI side sorting
    date: formatDateTime(singleResponse.day, 'DD MMM YYYY') ?? 'Unknown',
    timezone: timezone,
    ringSerialNumber: singleResponse.ringSerialNumber,
    bedtimeStart: bedtimeStart,
    bedtimeEnd: bedtimeEnd,
    bedtimeStartFull: singleResponse.bedtimeStart,
    bedtimeEndFull: singleResponse.bedtimeEnd,
    lowestHR: singleResponse.lowestHeartRate,
    temperature: temperature,
    sleepScore: singleResponse.sleepScore,
    nightlyIbiQuality: singleResponse.nightlyIbiQuality,
    dailyMotion: singleResponse.equivalentWalkingDistance,
    nonWearMinutes: nonWearMinutes,
    nonWearMinutesDuringBedtime: nonWearMinutesDuringBedtime,
    nonWearPeriods: singleResponse.nonWearPeriods,
    readinessScore: singleResponse.readinessScore,
    dhrIbiQuality: singleResponse.dhrIbiQuality,
    selfTestSuccess: singleResponse.selfTestSuccess,
    selfTestDetails: selfTestDetails,
    fieldTestSuccess: singleResponse.fieldTestSuccess,
    fieldTestDetails: singleResponse.fieldTestDetails ?? [],
    events: events,
    isExpandable: !!isExpandable && isExpandable > 0,
  }
}

/**
 * Extract list of summary data response items from summary data response
 *
 * @param summaryDataResponse   Summary data response
 * @param displayTimezone       Display dates and times in certain timezone
 */
export function extractSummaryDataFromResponse(
  summaryDataResponse: SummaryDataResponse,
  displayTimezone: DisplayTimezone = DisplayTimezone.MEMBER,
): SummaryDataReportItem[] {
  const summaryData: SummaryDataReportItem[] = []
  for (const item of summaryDataResponse.summaryData) {
    summaryData.push(extractSingleSummaryDataReportItemFromResponse(item, displayTimezone))
  }
  return summaryData
}
