<template>
  <v-container v-if="!userFound" class="px-12 mt-7">
    <v-card>
      <v-card-title primary-title class="headline mb-4">User not found</v-card-title>

      <v-card-text>
        <p>
          No user found with this UUID:
          <strong>{{ uuid }}</strong>
        </p>
        <p>Please check UUID and try again.</p>
      </v-card-text>

      <v-card-actions class="px-4 pt-5">
        <v-spacer />
        <v-btn variant="text" @click="$router.back()">Back</v-btn>
        <v-btn variant="text" :to="{ path: '/' }">Home</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>

  <v-container
    v-else-if="zdTicketNotFound"
    class="d-flex align-center justify-center px-12 mt-7"
    style="height: calc(100vh - 160px)"
  >
    <AskConsent v-model:zd-ticket-status="zdTicketNotFound" v-model:agent-reason="agentReason" :uuid="uuid" />
  </v-container>
  <v-container v-else-if="user?.uuid === routeUuid && !zdTicketNotFound" class="user container">
    <v-app-bar>
      <v-tabs :model-value="tab" style="max-width: 560px">
        <v-tab value="account" :to="'/users/' + uuid + '/account'">Account information</v-tab>
        <v-tab
          v-if="access || rights.includes('allowSensitiveDataAccess')"
          value="health"
          :to="'/users/' + uuid + '/health'"
        >
          Health information
        </v-tab>
        <v-tab
          v-if="access || rights.includes('allowDataDownloadAccess')"
          value="data"
          :to="'/users/' + uuid + '/data'"
        >
          Raw data browser
        </v-tab>
      </v-tabs>

      <v-spacer />

      <v-chip
        size="large"
        class="pa-3 mr-2 font-weight-bold"
        variant="text"
        append-icon="mdi-shield-check"
        :text="user.hipaaStatus ? 'HIPAA Customer' : 'No HIPAA association'"
        :color="user.hipaaStatus ? 'error' : 'success'"
      />

      <AccountActions :user="user" :rings="user.rings" :personal="access" />
    </v-app-bar>

    <div id="accountAlerts" />
    <v-window :model-value="tab">
      <v-window-item value="account">
        <template v-if="tab === 'account'">
          <AccountHeading :step="step" />
          <v-window :model-value="step">
            <v-window-item value="overview">
              <AccountOverview v-model:ring="ring" :user="user" />
            </v-window-item>

            <v-window-item value="battery">
              <BatteryDetails v-model:ring="ring" :user="user" />
            </v-window-item>

            <v-window-item value="devices">
              <DevicesSummary :ring="ring" :user="user" :ring-details="ringDetails" />
            </v-window-item>

            <v-window-item value="membership">
              <MembershipDetails v-model:ring="ring" :user="user" />
            </v-window-item>

            <v-window-item value="troubleshooter">
              <DataTroubleshooter v-model:ring="ring" :uuid="uuid" />
            </v-window-item>
          </v-window>
        </template>
      </v-window-item>

      <v-window-item value="health">
        <template v-if="tab === 'health'">
          <HealthParameters :step="step" />

          <v-window :model-value="step">
            <v-window-item v-if="arrhythmiaToolEnabled" value="arrhythmia">
              <ArrhythmiaTool v-model:ring="ring" :user="user" :disabled="!consent" />
            </v-window-item>
            <v-window-item value="reproductivity">
              <CycleQAInsights v-model:ring="ring" :user="user" :disabled="!consent" />
            </v-window-item>
          </v-window>
        </template>
      </v-window-item>

      <v-window-item value="data">
        <template v-if="tab === 'data'">
          <DataFilesSummary :step="step" />

          <v-window :model-value="step">
            <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="cconfig">
              <ClientConfigDiff :uuid="uuid" :range="true" :ring-serial="ring ? ring.serialNumber : ''" />
            </v-window-item>

            <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="assa">
              <AssaDataViewer :uuid="uuid" />
            </v-window-item>

            <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="debug">
              <DebugLogsSearch :uuid="uuid" />
            </v-window-item>

            <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="streams">
              <DataStreamsOrbs :uuid="uuid" :env="user.env && user.env.environment" :rings="user.rings" />
            </v-window-item>

            <v-window-item v-if="access || rights.includes('allowDataDownloadAccess')" value="files">
              <DataFilesListing :uuid="uuid" :env="user.env && user.env.environment" :rings="user.rings" />
            </v-window-item>
          </v-window>
        </template>
      </v-window-item>
    </v-window>
  </v-container>

  <v-progress-circular v-else size="96" class="d-flex" style="margin: calc(50vh - 96px) auto 0 auto" />
</template>

<script lang="ts">
  import { uniqBy } from 'lodash-es'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'
  import { ZendeskTicketChecker } from '#mixins/zendeskTicketChecker'

  import { resetAllPiniaStores } from '#utils/store/reset'

  import { AppStore, PrefsStore, RemoteStore, RingsStore, UserStore } from '#stores'

  import { Ring, RingDetails } from '#types'

  @Component
  class MemberDetailsView extends mixins(DateTime, ZendeskTicketChecker) {
    @Prop() public tab!: string
    @Prop() public step!: string

    @Prop() public uuid!: string

    public userStore = new UserStore()
    public prefsStore = new PrefsStore()
    public appStore = new AppStore()
    public ringsStore = new RingsStore()
    public remoteStore = new RemoteStore()

    public zdTicketNotFound = false
    public userFound = true
    protected text: string = ''
    public agentReason: string = ''

    public logAnalyticsEvent = logEvent

    public get routeUuid(): string | string[] {
      return this.$route.params?.uuid ?? ''
    }

    public get user() {
      return this.userStore.user
    }

    public get ringDetails(): RingDetails | null {
      return this.ringsStore.ring
    }

    public get ring(): Ring | null {
      return this.ringsStore.activeRing || null
    }

    public set ring(newRing: any) {
      this.ringsStore.setActiveRing(newRing)
    }

    public get rights() {
      return this.appStore.activeRights
    }

    public get access() {
      return this.userStore.user?.ownerAccess || this.userStore.user?.sharedAccess || this.userStore.user?.supportAccess
    }

    public get consent() {
      return this.userStore.consent
    }

    public get dataWait() {
      return this.userStore.dataWait
    }

    public get uniqueEmailAddresses() {
      return uniqBy(this.user?.emails || [], 'email').length
    }

    public get previousAgentReason(): string {
      return localStorage.getItem('previousAgentReason') || ''
    }

    public get arrhythmiaToolEnabled() {
      return this.$featureEnabled('arrhythmiaTool') && this.rights.includes('allowArrhythmiaToolAccess')
    }

    public updateRing(newRing: any) {
      this.ringsStore.setActiveRing(newRing)
    }

    @Watch('uuid')
    protected async onUuidChanged(_val: string, _prevVal: string) {
      // When member ID changes, we want to flush all Pinia stores to ensure there is no previous member's data left
      resetAllPiniaStores()
      // Check if user has open zendesk tickets or if darwin user has sufficient permissions before showing the user
      this.setAgentReason(this.previousAgentReason)
      this.zdTicketNotFound = false

      const user = await this.userStore.getUserAccessLevels(this.uuid)
      const sensitiveDataVisible = this.userAccessLevels?.ownerAccess || this.userAccessLevels?.supportAccess

      if (!user) {
        this.userFound = false
        return
      }

      // Check if the darwin user has access or if user has open zendesk tickets
      const response = await this.getUsersTicketStatus(this.uuid)
      if ('zdTicketNotFound' in response) {
        this.zdTicketNotFound = response['zdTicketNotFound'] ? true : false
      } else if ('reason' in response) {
        this.setAgentReason(response['reason'] ? response['reason'] : '', false)
      }

      if (this.zdTicketNotFound) {
        return
      } else if (this.agentReason) {
        // Get user data
        this.userStore.setSensitiveDataVisible(sensitiveDataVisible, this.uuid).then(() => {
          if (this.uuid) {
            this.userStore.getUser({
              user: this.uuid,
              agentReason: this.agentReason,
              hideRingNames: [],
            })
          }
        })
      }
    }

    @Watch('ring', { immediate: true })
    protected onRingChanged() {
      if (this.ring && this.ring.serialNumber) {
        this.ringsStore.getRingDetails(this.ring.serialNumber)
      }
    }

    @Watch('user')
    protected onUserChanged(_val: any, _prevVal: any) {
      // Check that this.ring actually belongs to this.user. If not, set ring to null
      if (
        this.ring &&
        this.user &&
        this.user.rings &&
        this.user.rings[0] &&
        !this.user.rings.find((r) => r.serialNumber == this.ring?.serialNumber)
      ) {
        this.ringsStore.setActiveRing(null)
      }

      if (!this.ring && this.user?.rings[0]) {
        this.ringsStore.setActiveRing(this.user.rings[0])
      }
    }

    public mounted() {
      this.setAgentReason(this.previousAgentReason)
      this.onUuidChanged(this.uuid, this.uuid)
    }

    private setAgentReason(reason: string, setPrevious: boolean = false) {
      if (reason) {
        this.agentReason = reason
      }

      localStorage.setItem('agentReason', reason)
      if (setPrevious) {
        localStorage.setItem('previousAgentReason', reason)
      }
    }
  }

  export default toNative(MemberDetailsView)
</script>

<style lang="scss" scoped>
  :deep(.v-stepper-item--selected) {
    .v-avatar {
      color: rgb(var(--v-theme-foreground));
      background: rgb(var(--v-theme-primary));
    }
  }

  :deep(.v-input) {
    .v-input__prepend-inner {
      opacity: 0.4;
    }

    &.active .v-input__prepend-inner {
      opacity: 1;

      & button {
        color: #2196f3;
      }
    }
  }

  .user :deep(.v-item-group),
  .user :deep(.v-tabs-items) {
    background: transparent !important;
  }

  .user :deep(.v-expansion-panel) {
    background: transparent !important;

    &::before {
      display: none;
    }

    .v-expansion-panel-header {
      padding: 0;
    }

    .v-expansion-panel-header__icon i {
      color: #e91e63 !important;
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .infos :deep(.v-text-field__slot) {
    input {
      text-align: right;
    }
  }
</style>
