import { AxiosError } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

const loadingData: any = {}

@Store()
export class TimelineStore extends BaseStore {
  public dataWait = false

  public features: any[] = []

  public setDataWait(dataWait: any) {
    loadingData[dataWait.api] = dataWait.value

    this.dataWait = Object.keys(loadingData).some((k) => loadingData[k])
  }

  public async getFeatureFlags(params: any) {
    const userStore = new UserStore()
    this.setDataWait({ api: 'featureFlags', value: true })
    this.features = []
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${params.uuid}/feature-flags` },
      'getFeatureFlags',
      params.uuid,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data?.featureFlags) {
      const featureContents = response?.data?.featureFlags
      const features: any[] = []
      for (const featureObject of featureContents) {
        const featuresExtraInfo = featureObject.extraInfo ? featureObject.extraInfo.split(',') : []
        const extraInfo: any = {}
        featuresExtraInfo.forEach((feature: any) => {
          const [key, val]: string[] = feature.split(':')
          extraInfo[key.trim()] = val?.trim()
        })
        featureObject.extraInfo = Object.keys(extraInfo).length ? extraInfo : null
        features.push(featureObject)
      }
      this.features = features
    }

    this.setDataWait({ api: 'featureFlags', value: false })
  }
}
