<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-container v-if="contactDetails" class="mt-n6" :min-height="180">
    <v-row>
      <v-col>
        <div class="text-subtitle-2">
          {{ contactTypeText }}
          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <span v-bind="props">
                <v-btn
                  icon="mdi-pencil"
                  variant="plain"
                  color="blue"
                  size="small"
                  class="mt-n2"
                  :disabled="!canEditAddress"
                  @click.stop="updateAddress()"
                />
              </span>
            </template>
            <span v-if="canEditAddress">Edit address</span>
            <span v-else>Editing address requires account edit rights</span>
          </v-tooltip>
        </div>
        <div>
          <p>Name: {{ contactDetails.firstName }} {{ contactDetails.lastName }}</p>
          <p>Email: {{ contactDetails.personalEmail }}</p>
          <p v-if="contactDetails.telephone">Phone: {{ contactDetails.telephone }}</p>
          <p>Address1: {{ contactDetails.address1 }}</p>
          <p v-if="contactDetails.address2">Address2: {{ contactDetails.address2 }}</p>
          <p v-if="contactDetails.state">State: {{ contactDetails.state }}</p>
          <p>
            {{ contactDetails.postalCode }} {{ contactDetails.city }},
            {{ contactDetails.country }}
          </p>
        </div>
      </v-col>
    </v-row>
    <ShippingDetails ref="shippingDetailsDialog" :member="member" @close="onShippingDialogClosed" />
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ShippingDetails } from '#views/members/billing/ShippingDetails.vue'

  import { getHumanReadableAddressType } from '#utils/utils'

  import { AppStore, SubscriptionStore, UserStore } from '#stores'

  import { ContactDetail, ContactType, ShippingDetailsDialogCloseEvent, ShippingDetailsDisabledInputs } from '#types'
  import { Member } from '#types'

  @Component({})
  class ContactDetails extends Vue {
    @Prop() public member!: Member
    @Prop() public contactDetails!: ContactDetail
    @Prop() public contactType!: ContactType

    public appStore = new AppStore()
    public userStore = new UserStore()
    public subscriptionStore = new SubscriptionStore()

    declare public $refs: {
      shippingDetailsDialog: ShippingDetails
    }

    public get canEditAddress(): boolean {
      return this.rights.includes('allowAccountEditAccess')
    }

    public get contactTypeText(): string {
      return this.contactType === 'billToContact' ? 'Bill to contact' : 'Ship to contact'
    }

    public get rights() {
      return this.appStore.activeRights || []
    }

    public async updateAddress() {
      const addressType = getHumanReadableAddressType(this.contactType)

      const text = `Updating the ${addressType} address for ${this.member?.email || 'this account'}.`

      const action = this.contactType === 'billToContact' ? 'updateBillingAddress' : 'updateShippingAddress'

      // Country field disabled for shipping address, because SUPA endpoint doesn't allow changing it https://ouraring.slack.com/archives/CTPBXAPJL/p1723032253104619
      const disabledInputs: ShippingDetailsDisabledInputs =
        this.contactType === 'shipToContact'
          ? { country: "Not allowed to change the country of a member's shipping address" }
          : {}

      await this.$refs.shippingDetailsDialog.open(
        action,
        `Update ${addressType} address`,
        text,
        null,
        this.contactDetails,
        disabledInputs,
      )
    }

    /**
     * Callback for when the shipping dialog is closed
     * Fetches the contact details again if there was an update
     * @param event The event emitted by the dialog
     */
    public onShippingDialogClosed(event: ShippingDetailsDialogCloseEvent) {
      if (event?.updated) {
        this.subscriptionStore.getContactDetails({ userId: this.member.uuid })
      }
    }
  }

  export default toNative(ContactDetails)
</script>

<style lang="scss" scoped>
  :deep(.v-btn) {
    margin-bottom: -2px;
  }
</style>
