import { UserSearchResponseSingleUser } from '#utils/user/search'

import { PrepaidMoveTargetMember } from '#types/prepaid'

export function extractPrepaidMoveTargetMemberSearchResult(
  memberSearchResult: UserSearchResponseSingleUser[],
): PrepaidMoveTargetMember[] {
  return memberSearchResult.map((result) => {
    return {
      email: result.email,
      uuid: result.uuid,
    }
  })
}
