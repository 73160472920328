import { DiagnosticErrorLevel, Nullable } from '#types'

export interface Subscriptions {
  subscriptions: SubscriptionInfo[] | []
  isPaymentMethodSet: boolean | null
  defaultPaymentMethodCardExpired: boolean | null
  dunningLevel: number
}

export interface ShippingDetailsInfo {
  firstName: string | undefined
  lastName: string | undefined
  personalEmail: string | undefined
  address1: string | undefined
  address2: string | undefined
  city: string | undefined
  country: string | undefined
  state?: string | undefined
  postalCode: string | undefined
}

export interface ShippingDetailsErrors {
  firstName: string[]
  lastName: string[]
  personalEmail: string[]
  address1: string[]
  address2: string[]
  city: string[]
  country: string[]
  state: string[]
  postalCode: string[]
  /* 
    generalError is used for the special case when the error response returns with the field as null,
   */
  generalError?: string[]
}

export type ShippingDetailsDialogAction =
  | 'startTrial'
  | 'upgradeLifetime'
  | 'updateShippingAddress'
  | 'updateBillingAddress'

export type ShippingDetailsDisabledInputs = {
  [key in keyof ShippingDetailsInfo]?: string
}

export type ShippingDetailsDialogCloseEvent = {
  action: Nullable<ShippingDetailsDialogAction>
  updated: boolean
}

export enum BillingAccountState {
  BILLING_ACCOUNT_EXISTS = 'BILLING_ACCOUNT_EXISTS',
  BILLING_ACCOUNT_REQUIRED = 'BILLING_ACCOUNT_REQUIRED',
}

export interface SubscriptionInfo {
  id: string
  isActive: boolean
  trialLengthInMonths?: number
  subscriptionType: string
  subscriptionState: OuraSubscriptionState
  startDate: string
  endDate?: string
  nextBillingDate: string | null
  nextPaymentDate: string | null
  pendingCancellation: boolean
  productRatePlanName?: string
  customerId?: string
  subscriptionFee: SubscriptionFee | null
  billingAccountState: BillingAccountState
  prepaidPeriods: PrepaidPeriods[]
}

export interface PrepaidPeriods {
  start: string
  end: string
}

interface SubscriptionFee {
  price: {
    amount: number
    currency: string
  }
  billingPeriod: {
    periodType: string
    periodLength?: number
  }
}

export interface PaymentsInfo {
  id: string
  status: string
  type: string
  reason: string
  amount: number
  appliedAmount: number
  unappliedAmount: number
  refundAmount: number
  creditBalanceAmount: number
  currency: string
  effectiveDate: string
  refunds?: Refund[]
  referenceId: string
  paymentGatewayState: string
  paymentGatewayResponse: string
  paymentGatewayResponseCode: string
  charges?: Charge[]
}

export enum ChargeBillingPeriod {
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS = 'Months',
  YEARS = 'Years',
}

interface Charge {
  id: string
  name: string
  billingPeriod: ChargeBillingPeriod
}

interface PaymentMethodBase {
  id: string
  type: string
  isDefault: boolean
  status: string
  email?: string
  createdAt: string
}

export interface PaymentMethodCreditCard extends PaymentMethodBase {
  cardType: string
  cardNumber: string
  expirationMonth: number
  expirationYear: number
  bankIdentificationNumber: string
}

export type PaymentMethod = PaymentMethodBase | PaymentMethodCreditCard

export interface ContactDetails {
  billToContact: ContactDetail | null
  shipToContact: ContactDetail | null
}

export type ContactType = 'billToContact' | 'shipToContact'

export interface ContactDetail {
  address1: string
  address2?: string
  city: string
  country: string
  firstName: string
  lastName: string
  personalEmail: string
  postalCode: string
  state?: string
  telephone?: string
}

export interface RefundsStatusInfo {
  status: RefundStatus
  class: string
}

export interface Refund {
  id: string
  markedForSubmissionOn: string
  reasonCode: string
  refundTransactionTime: string
  status: RefundStatus
}

export enum RefundStatus {
  Canceled = 'Canceled',
  Processed = 'Processed',
  Processing = 'Processing',
  Error = 'Error',
}

export enum OuraSubscriptionState {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  TRIAL = 'TRIAL',
  MEMBER = 'MEMBER',
  LIFETIME = 'LIFETIME',
  EXPIRED = 'EXPIRED',
}

export interface SubscriptionHistoryEvent {
  /**
   * Oura member ID.
   * Returned from SUPA in every event.
   *
   * @example "ec8625f8-d8cf-4831-8ccd-dbb83ef7ffba"
   */
  accountId: string

  /**
   * Type of the event
   *
   * @example "SUBSCRIPTION_TRIAL_EXTENDED"
   * @example "EMAIL_SENT"
   */
  type: string

  /**
   * Event timestamp (format: date-time)
   *
   * @example "2022-08-23T10:37:05.903Z"
   */
  createdAt: string

  /**
   * Subscription ID
   *
   * @example "16f6eaa5a1ad0518267de9456304d6c6"
   */
  subscriptionId?: string

  /**
   * Reason
   *
   * @example "Upgrade/Downgrade"
   * @example "Warranty exchange"
   */
  reason?: string

  /**
   * Escalation reason
   *
   * @example "Escalation due to X"
   */
  escalationReason?: string

  /**
   * Issue start date
   *
   * @example "2024-10-18"
   */
  issueStartDate?: string

  /**
   * Compensation length in months
   *
   * @example 2
   */
  compensationLengthInMonths?: number

  agentEmail?: string

  /**
   * Payment amount
   *
   * @example 5.99
   */
  amount?: number

  isFirst?: boolean
}

export interface SubscriptionAuditLog {
  subscriptionId: string
  createdAt: string
  eventType: string
  email: string
  json?: any
}

export interface Invoice {
  date: string
  status: string
  amount: number
  balance: number
  invoiceId: string
}

export interface CreditMemo {
  id: string
  issueStartDate: string
  amount: number
  unappliedAmount: number
  isCompensation: boolean
  compensationReason: string
  compensationEscalationReason: string
}

export interface DiagnosticAlert {
  id: string
  type: DiagnosticErrorLevel
  color: string
  message: string
  tooltip: {
    headers: {
      title: string
      key: string
      align?: string
    }[]
    items: any
  }
  link: string
  macro?: {
    title?: string
    id: string
    actions?: string[]
  }
}

export interface DataFile {
  /**
   * Bucket name
   *
   * Example: "ring-stream"
   */
  bucket: string

  /**
   * Environment
   *
   * Example: "dev"
   */
  env: string

  /**
   * Key (?)
   *
   * Example: "stream-full/0783ab65-063b-4a31-aaec-e7d3f861464f/JZLOG/d7d34c97-6d73-4828-9f75-c156e39ea117"
   */
  key: string

  /**
   * Last modified timestamp
   *
   * Example: "2022-07-25T22:41:33+00:00"
   */
  lastModified?: string

  /**
   * File name
   * Example: "ac483f42-06b3-4a05-a479-d8ddb3facdac-6ab08d2d-bdd3-4d2b-9641-b4a878d4ccfc.jzlog"
   */
  name?: string

  /**
   * File size in bytes
   *
   * For example: 1368137
   */
  size?: number

  /**
   * File type
   *
   * Examples: "jzlog", "zip"
   */
  type?: string

  /**
   * Anonymize JZLOG
   */
  anonymizeJzlog?: boolean
}

export interface GetFileUrlRequest {
  key: string[]
  environment: string
  bucket: string
  save_as: string
  anonymizeJzlog?: boolean
}

/**
 * Download preference for a single file.
 *
 * Currently we can choose whether we want to download sensitive data version of the file or not.
 */
export interface FileDownloadPreference {
  downloadSensitiveData: boolean
  file: DataFile
}

export interface WarrantyStateStatus {
  state: any
  warrantyState: 'success' | 'error' | 'warning' | 'not_enough_data'
}

export interface WarrantyStatusAndEndDate {
  status: string
  endDate: string
}

export interface AssaModelsResponse {
  models: string[]
}

export interface RingHardwareSyncInfo {
  lastSyncDate: string | null
  alertColor: string
  clockIcon: string
  syncMessage: string
}

export interface MemberInfo {
  warrantyStartDate: Nullable<string>
  warrantyEndDate: Nullable<string>
  warrantyDaysLeftText: Nullable<string>
  warrantyEndDateClass: Nullable<string>
}

export interface AgentReason {
  mainReason: string
  requiresExtraReason?: boolean
  extraReasons?: ExtraAgentReason[]
  requiresTextExplanation?: boolean
  textExplanationLabel?: string
}

export interface ExtraAgentReason {
  reason: string
  requiresTextExplanation?: boolean
  textExplanationLabel?: string
}
