<template>
  <div v-if="ring && !dataWait" class="px-3">
    <v-banner
      lines="two"
      rounded="lg"
      max-height="48"
      :stacked="true"
      :bg-color="bannerColor"
      density="compact"
      class="mb-2 custom-banner"
      :text="warrantyMessage"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { alertColors } from '#views/members/constants'

  import { getWarrantyInfo } from '#utils/user/warrantyDetails'

  import { RingsStore } from '#stores'

  import { Ring, WarrantyData } from '#types'

  @Component
  class RingWarranty extends mixins(DateTime) {
    @Prop() public ring!: Ring

    public ringsStore = new RingsStore()
    public warrantyMessage: string = 'No warranty data available'
    public warrantyDates: string = 'No warranty dates available'
    public bannerColor: string = 'grey'

    @Watch('ring')
    protected onRingChanged() {
      this.ringsStore.getWarrantyData({ serial: this.ring.serialNumber, initial: true })
    }

    @Watch('warrantyData')
    protected onWarrantyDataChanged() {
      if (this.warrantyData?.ringset?.warrantyStartDate) {
        let { state, warrantyState } = getWarrantyInfo(
          this.warrantyData.ringset.warrantyStartDate,
          this.warrantyData.ringset.warrantyEndDate,
          this.warrantyData.ringset.escEndDate,
        )
        if (state !== 'unknown') {
          this.warrantyMessage = `${state}: ${this.warrantyData.ringset.warrantyStartDate ? this.formatDateTime(this.warrantyData.ringset.warrantyStartDate, 'DD MMM YYYY') : ''} ${this.formatDateTime(this.warrantyData.ringset.warrantyEndDate, '→ DD MMM YYYY')} \n`
          this.warrantyMessage += `ESC End Date:  ${this.warrantyData.ringset.escEndDate ? this.formatDateTime(this.warrantyData.ringset.escEndDate, 'DD MMM YYYY') : 'No date available'}`
          this.bannerColor = alertColors[warrantyState]
        }
      }
    }

    public get warrantyData(): WarrantyData | null {
      return this.ringsStore.warrantyData
    }
    public get dataWait(): boolean {
      return this.ringsStore.dataWait
    }
  }

  export default toNative(RingWarranty)
</script>

<style lang="scss" scoped>
  .custom-banner {
    white-space: pre-wrap;
  }
  :deep(.v-banner__content) {
    font-size: 14px;
    word-spacing: -1px;
  }
  :deep(.v-banner-text) {
    padding-inline-end: 0px;
  }
  .v-banner--density-compact.v-banner--two-line {
    padding-top: 1px;
    padding-bottom: 1px;
  }
</style>
