import { ReturnEligibilityInfo, ReturnEligibilityInfoDisplay } from '#types'

export function getReturnEligibilityInfoDisplay(
  returnEligibility: ReturnEligibilityInfo | null,
): ReturnEligibilityInfoDisplay | null {
  if (!returnEligibility) {
    return null
  }

  const { eligibleForReturn, thresholdDays, daysSinceFulfillment } = returnEligibility
  const daysRemaining = Math.max((thresholdDays ?? 0) - (daysSinceFulfillment ?? 0), 0) // Ensure daysRemaining is never negative
  const isEligible = eligibleForReturn ?? false

  return {
    isEligible: isEligible,
    daysRemaining,
    statusIcon: eligibleForReturn ? 'mdi-package-variant-closed' : 'mdi-package-variant-closed-remove',
    statusColor: eligibleForReturn ? 'green' : 'red',
    statusMessage: eligibleForReturn ? 'Eligible for return' : 'Not eligible for return',
  }
}

export function getReturnEligibilityMessage(eligibleForReturn?: boolean | null): string {
  return eligibleForReturn ? 'eligible for return' : 'not eligible for return'
}
