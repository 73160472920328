<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <div>
    <v-dialog
      v-model="isOpen"
      width="800"
      class="backdrop"
      @click:outside="closeDialog('click-outside')"
      @keydown="closeDialog($event.keyCode)"
    >
      <v-card v-if="!action">
        <v-card-title>Prepaid actions</v-card-title>
        <v-card-subtitle>Manage member's prepaid periods</v-card-subtitle>

        <v-card-text class="d-flex flex-column ga-5 mt-6">
          <v-btn
            v-if="prepaidApplyFeatureEnabled"
            size="large"
            variant="outlined"
            prepend-icon="mdi-cash-plus"
            @click="setAction(prepaidAction.APPLY)"
          >
            Apply
          </v-btn>
          <span class="d-flex flex-column">
            <v-btn
              v-if="prepaidRemoveFeatureEnabled"
              size="large"
              variant="outlined"
              prepend-icon="mdi-cash-minus"
              :disabled="!memberHasPrepaidPeriods"
              @click="setAction(prepaidAction.REMOVE)"
            >
              Remove
            </v-btn>
            <v-tooltip v-if="!memberHasPrepaidPeriods" activator="parent">
              No prepaid periods found on selected subscription.
            </v-tooltip>
          </span>
          <span class="d-flex flex-column">
            <v-btn
              v-if="prepaidMoveFeatureEnabled"
              size="large"
              variant="outlined"
              prepend-icon="mdi-cash-multiple"
              :disabled="!memberHasPrepaidPeriods"
              @click="setAction(prepaidAction.MOVE)"
            >
              Move to another account
            </v-btn>
            <v-tooltip v-if="!memberHasPrepaidPeriods" activator="parent">
              No prepaid periods found on selected subscription.
            </v-tooltip>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
      <PrepaidApply v-if="action === prepaidAction.APPLY" :member="member" @close="close" @back="action = null" />
      <PrepaidRemove v-if="action === prepaidAction.REMOVE" :member="member" @close="close" @back="action = null" />
      <PrepaidMove v-if="action === prepaidAction.MOVE" @close="close" @back="action = null" />
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { PrepaidAction } from '#views/members/billing/prepaid-actions/constants'

  import { Member, SubscriptionInfo } from '#types'

  @Component
  export class PrepaidActions extends Vue {
    @Prop() public member!: Member

    public isOpen = false
    public subscription: SubscriptionInfo | null = null
    public prepaidAction = PrepaidAction

    public action: PrepaidAction | null = null

    public get memberHasPrepaidPeriods(): boolean {
      if (this.subscription?.prepaidPeriods) {
        return this.subscription.prepaidPeriods.length > 0
      }
      return false
    }

    public get prepaidMoveFeatureEnabled(): boolean {
      return this.$featureEnabled('prepaidMove')
    }

    public get prepaidApplyFeatureEnabled(): boolean {
      return this.$featureEnabled('prepaidApply')
    }

    public get prepaidRemoveFeatureEnabled(): boolean {
      return this.$featureEnabled('prepaidRemove')
    }

    public setAction(action: PrepaidAction | null) {
      this.action = action
    }

    public async open(subscription: SubscriptionInfo) {
      this.action = null

      this.subscription = subscription
      this.isOpen = true
    }

    public async close() {
      this.isOpen = false
    }

    /***
     * Handle events from clicking outside v-dialog
     * or closing the dialog by pressing ESC
     * @param event
     */
    public closeDialog(event: string | number) {
      if (event == 'click-outside' || event == 27) {
        this.close()
      }
    }
  }

  export default toNative(PrepaidActions)
</script>
