<template>
  <div class="pa-4">
    <div class="text-overline">
      Feature Flags
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn variant="plain" v-bind="props" icon="mdi-content-copy" color="grey" @click="copyContents()" />
        </template>
        {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
      </v-tooltip>
    </div>

    <v-tabs v-model="tab">
      <v-tab v-for="n in Object.keys(featureFlags)" :key="n" :text="`${n}`" :value="n" />
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-list v-for="item in featureFlags[tab]" :key="item" :title="item">
        <v-list-item>{{ item }}</v-list-item>
      </v-list>
    </v-tabs-window>
  </div>
</template>

<script lang="ts">
  import { defaultFeatureFlags } from '#main'

  import { useClipboard } from '@vueuse/core'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { getAll } from 'firebase/remote-config'

  import { DarwinFeatureFlags } from '#types'

  @Component
  class DarwinFeatures extends Vue {
    public tab: 'enabled' | 'disabled' = 'enabled'

    public featureFlags: DarwinFeatureFlags = { enabled: [], disabled: [] }

    public useClipboard = useClipboard()

    public mounted() {
      Object.entries(getAll(this.$remoteConfig)).forEach(([item, values]) => {
        if (item in defaultFeatureFlags) {
          const status = Object.values(values)[1]

          if (status == 'true') {
            this.featureFlags.enabled.push(item)
          } else {
            this.featureFlags.disabled.push(item)
          }
        }
      })
    }

    public copyContents() {
      let copyContents = this.tab.charAt(0).toUpperCase() + this.tab.slice(1) + ' feature flags: \n'

      if (this.tab) {
        copyContents += this.featureFlags[this.tab].toString().replaceAll(',', '\n')
      }

      this.useClipboard.copy(copyContents)
    }
  }
  export default toNative(DarwinFeatures)
</script>
<style>
  :deep(.v-list-item--density-default.v-list-item--one-line) {
    min-height: 25px;
  }
  :deep(.v-btn__content) {
    font-size: 12px;
  }
  :deep(.v-slide-group__next, .v-slide-group__prev) {
    flex: 0 20px;
  }
  :deep(.v-tab.v-tab.v-btn) {
    min-width: 20px;
  }
  :deep(.v-navigation-drawer .v-list) {
    padding-bottom: 2px;
    font-size: 14px;
  }
</style>
