<template>
  <v-card :loading="removingPrepaid">
    <v-card-title>Remove the latest prepaid period</v-card-title>
    <v-card-subtitle>Remove the latest applied prepaid period from the member's account</v-card-subtitle>
    <v-card-text>
      <v-progress-circular
        v-show="removingPrepaid"
        indeterminate
        size="70"
        width="8"
        style="position: absolute; top: 50%; left: 45%; z-index: 100"
      />
      <v-row ref="notification">
        <div v-if="notificationMessage" style="position: sticky; top: 60px; width: 100%">
          <v-sheet min-height="50">
            <v-alert v-if="notificationMessage" :type="notificationType">
              <div>
                {{ notificationMessage }}
              </div>
            </v-alert>
          </v-sheet>
        </div>
      </v-row>
      <v-row class="ml-1">
        <v-alert type="info">
          <div>
            <p>If the prepaid plan is already in use, select the checkbox below.</p>
            <p>Otherwise, click the Confirm button to proceed.</p>
          </div>
        </v-alert>
      </v-row>
      <v-row class="ml-1">
        <v-checkbox v-model="forceRemove" :disabled="disableOnSuccess" label="Force remove" />
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-btn-group>
        <v-btn color="primary" variant="text" :disabled="removingPrepaid" @click="back()">Back</v-btn>
      </v-btn-group>
      <v-btn-group>
        <v-btn color="primary" variant="text" :disabled="removingPrepaid" @click="close()">Close</v-btn>
        <v-btn color="primary" variant="text" :disabled="disableOnSuccess" @click="removePrepaid()">Confirm</v-btn>
      </v-btn-group>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { PrepaidStore, SubscriptionStore } from '#stores'

  import { Member } from '#types/common.js'

  @Component
  export class PrepaidRemove extends Vue {
    @Prop() public member!: Member

    public prepaidStore = new PrepaidStore()
    public subscriptionStore = new SubscriptionStore()

    public forceRemove: boolean = false
    public notificationMessage: string = ''
    public notificationType: 'success' | 'error' = 'success'
    public justSaved: boolean = false

    public get removingPrepaid() {
      return this.prepaidStore.removingPrepaidPlan
    }

    public get disableOnSuccess(): boolean {
      return this.removingPrepaid || this.justSaved
    }

    public async removePrepaid() {
      await this.prepaidStore.removePrepaid({
        memberId: this.member.uuid,
        forceRemove: this.forceRemove,
      })

      const requestError = this.prepaidStore.prepaidRemoveError
      if (requestError) {
        this.notificationMessage = requestError?.userMessage ?? 'Something went wrong'
        this.notificationType = 'error'
      } else {
        this.notificationMessage = 'Prepaid plan removed successfully'
        this.notificationType = 'success'
        this.justSaved = true
        await this.subscriptionStore.getSubscriptions({ initial: false, userId: this.member.uuid })
      }
    }

    public reset() {
      this.forceRemove = false
      this.justSaved = false
      this.notificationMessage = ''
      this.prepaidStore.$reset()
    }

    public close() {
      this.reset()
      this.$emit('close')
    }

    public back() {
      this.reset()
      this.$emit('back')
    }
  }

  export default toNative(PrepaidRemove)
</script>
