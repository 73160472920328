import { TeamsOrganizationDetails, TeamsOrganizationDetailsResponse } from '#types'

/**
 * Extract single Oura Teams organization summary item.
 *
 * @param detailsResponse   Oura Teams organization details response item
 */
export function extractOrganizationDetailsFromResponse(
  detailsResponse: TeamsOrganizationDetailsResponse,
): TeamsOrganizationDetails {
  return <TeamsOrganizationDetails>{
    name: detailsResponse.name,
    uid: detailsResponse.uid,
    type: detailsResponse.type,
    createdAt: detailsResponse.createdAt,
    memberCount: detailsResponse.memberCount,
    coachCount: detailsResponse.coachCount,
    groupCount: detailsResponse.groupCount,
    accountManagerEmail: detailsResponse.accountManagerEmail ?? null,
    externalId: detailsResponse.externalId ?? null,
    planName: detailsResponse.planName,
    subscriptionStatus: detailsResponse.subscriptionStatus ?? null,
    validTo: detailsResponse.validTo ?? null,
    admins: detailsResponse.admins,
    permissions: detailsResponse.permissions,
  }
}
