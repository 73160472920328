import JSON5 from 'json5'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { Nullable, PlotlyFigure, SleepPeriodsResponse } from '#types'

const REQUEST_SOURCE_GET_SLEEP_PERIODS = 'getSleepPeriods'
const REQUEST_SOURCE_GET_ARRHYTHMIA_IBI_DATA = 'getArrhythmiaIbiData'
const REQUEST_SOURCE_GET_ARRHYTHMIA_PPG_DATA = 'getArrhythmiaPpgData'
@Store()
export class ArrhythmiaStore extends BaseStore {
  public dataWait = false
  public ibiPlotData: Nullable<PlotlyFigure> = null
  public ppgPlotData: PlotlyFigure[] = []
  public sleepPeriods: Nullable<SleepPeriodsResponse> = null

  public get sleepPeriodsRequestError(): string {
    return this.getArrhythmiaRequestError(REQUEST_SOURCE_GET_SLEEP_PERIODS)
  }

  public get ibiPlotDataRequestError(): string {
    return this.getArrhythmiaRequestError(REQUEST_SOURCE_GET_ARRHYTHMIA_IBI_DATA)
  }

  public get ppgPlotDataRequestError(): string {
    return this.getArrhythmiaRequestError(REQUEST_SOURCE_GET_ARRHYTHMIA_PPG_DATA)
  }

  public waitingForSleepPeriods(): boolean {
    return this.waitingForData([REQUEST_SOURCE_GET_SLEEP_PERIODS])
  }

  public getArrhythmiaRequestError(requestSource: string): string {
    const error = this.getRequestError(requestSource)
    if (error) {
      return error.userMessage ?? 'Something went wrong. Please try again.'
    }
    return ''
  }

  public async getSleepPeriods(uuid: string, timestampFrom: string, timestampTo: string) {
    this.sleepPeriods = null
    this.resetRequestError(REQUEST_SOURCE_GET_SLEEP_PERIODS)
    this.updateDataWait({ source: REQUEST_SOURCE_GET_SLEEP_PERIODS, wait: true })

    const url = `/api/v1/users/${uuid}/sleep-periods`
    const params = { timestampFrom: timestampFrom + 'T00:00:00', timestampTo: timestampTo + 'T23:59:59' }
    await this.makeRequest({ method: 'get', url: url, params: params }, REQUEST_SOURCE_GET_SLEEP_PERIODS)
      .then((response: AxiosResponse | null) => {
        if (response?.data?.sleepPeriods) {
          this.sleepPeriods = response?.data
        }
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_GET_SLEEP_PERIODS)
      })

    this.updateDataWait({ source: REQUEST_SOURCE_GET_SLEEP_PERIODS, wait: false })
  }

  public async getIbiPlotData(uuid: string, timestampFrom: string, timestampTo: string) {
    this.dataWait = true
    this.ibiPlotData = null
    this.resetRequestError(REQUEST_SOURCE_GET_ARRHYTHMIA_IBI_DATA)

    const url = `/api/v1/users/${uuid}/arrhythmia-ibi`
    const params = { timestampFrom: timestampFrom, timestampTo: timestampTo }
    await this.makeRequest({ method: 'get', url: url, params: params }, REQUEST_SOURCE_GET_ARRHYTHMIA_IBI_DATA)
      .then((value: AxiosResponse | null) => {
        const ibiPlotDataResponse: string | undefined = value?.data?.graph
        if (ibiPlotDataResponse) {
          this.ibiPlotData = JSON5.parse(window.atob(ibiPlotDataResponse))
        }
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_GET_ARRHYTHMIA_IBI_DATA)
      })

    this.dataWait = false
  }

  public async getPPGPlotData(uuid: string, timestampFrom: string, timestampTo: string) {
    this.dataWait = true
    this.ppgPlotData = []
    this.resetRequestError(REQUEST_SOURCE_GET_ARRHYTHMIA_PPG_DATA)

    const url = `/api/v1/users/${uuid}/arrhythmia-ppg`
    const params = { timestampFrom: timestampFrom, timestampTo: timestampTo }
    await this.makeRequest({ method: 'get', url: url, params: params }, REQUEST_SOURCE_GET_ARRHYTHMIA_PPG_DATA)
      .then((value: AxiosResponse | null) => {
        const ppgDataResponse: any = value?.data?.figures
        if (ppgDataResponse) {
          for (const graph in ppgDataResponse) {
            this.ppgPlotData.push(JSON5.parse(window.atob(ppgDataResponse[graph]?.graph)))
          }
        }
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_GET_ARRHYTHMIA_PPG_DATA)
      })

    this.dataWait = false
  }

  public resetData() {
    this.ibiPlotData = null
    this.ppgPlotData = []
    this.sleepPeriods = null
  }
}
