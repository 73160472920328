<template>
  <NoRingBanner v-if="!user.rings.length" />

  <LastSyncInfo v-else-if="ring" :ring="ring" />

  <OverviewInfo v-if="user" v-model:ring="ring" :user="user" :rings="user.rings" class="mt-8" />

  <DiagnosticsResult class="mt-8" :user="user" :ring="ring" :sidecar="false" />

  <SummaryReport v-if="user" class="mt-8" :user-uuid="user.uuid" />
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { Member, Ring } from '#types'

  @Component
  class AccountOverview extends mixins(DateTime) {
    @Prop() public user!: Member
    @Prop() public ring!: Ring

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Dashboard',
        page_location: window.location.toString().split('?')[0],
      })
    }
  }

  export default toNative(AccountOverview)
</script>

<style lang="scss" scoped>
  :deep(.legacy) {
    .v-select__selection {
      color: red !important;
    }
  }
  :deep(.v-banner__content) {
    font-size: large;
  }
</style>
