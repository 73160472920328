import { RingDetails, RingDetailsResponse, WarrantyData, WarrantyDetailsResponse } from '#types'

export function extractRingDetailsFromRingDetailsResponse(ringDetailsResponse: RingDetailsResponse): RingDetails {
  // For now data format is identical. If and when external API changes, we now have separate internal data format
  /*
    We need osVersionUpdatedOn in devices and updatedOn in clients to be added to ringDetails for use in ClientInfo.vue.  
  */
  const updatedData = {
    deviceOsVersionUpdatedOn: ringDetailsResponse.devices[0]?.osVersionUpdatedOn,
    clientUpdatedOn: ringDetailsResponse.clients[0]?.updatedOn,
  }
  return { ...ringDetailsResponse.ring, ...updatedData }
}

export function extractWarrantyDataFromWarrantyDetailsResponse(
  warrantyDetailsResponse: WarrantyDetailsResponse,
): WarrantyData {
  // For now data format is identical. If and when external API changes, we now have separate internal data format
  return { ...warrantyDetailsResponse }
}
