import { Component, Vue } from 'vue-facing-decorator'

import { PrefsStore, UserStore } from '#stores'

/**
 * @deprecated This class should not be used anymore, will be removed in the future.
 */
@Component
export class DateTime extends Vue {
  protected readonly userStore = new UserStore()
  protected readonly prefsStore = new PrefsStore()

  public get userTimezone(): number {
    return this.userStore.timezone
  }

  public get prefsTimezoneSetting() {
    // this can either be 'USER' or 'UTC'
    return this.prefsStore.getTimezoneSetting
  }

  public get prefsTimezoneNotAllowed(): boolean {
    return !this.prefsStore.timezoneAllowed()
  }

  public utcInUse(): boolean {
    if (this.prefsTimezoneSetting !== 'UTC') {
      const path = this.$router.currentRoute.value.path

      const user = this.userStore.user

      if (user && path.includes(`/users/${user.uuid}`)) {
        return false
      }
    }
    return true
  }

  public getTimezone(): number {
    return this.utcInUse() ? 0 : this.userTimezone || 0
  }

  public formatDateTime(date: any, format: string, timezone?: number) {
    if (!date) {
      return 'Unknown'
    }
    if (this.utcInUse()) {
      return `${this.$dayjs.utc(date).format(format)} ${format?.toLowerCase().includes('h') ? (timezone ?? 'UTC') : ''}`
    } else {
      const userTimezone = timezone ?? this.userTimezone
      return userTimezone >= 0
        ? `${this.$dayjs.utc(date).add(Math.abs(userTimezone), 'minute').format(format)}`
        : `${this.$dayjs.utc(date).subtract(Math.abs(userTimezone), 'minute').format(format)}`
    }
  }

  public getDate(ts: number, days: number) {
    const date = new Date(ts)

    date.setDate(date.getDate() + days)

    return date.getTime()
  }

  public getTime(ts: number, ms: number) {
    const day = new Date()

    return ts - day.getTimezoneOffset() * 60 * 1000 + ms
  }

  public getDateString(ts: number, days?: number, time?: string) {
    const date = new Date(days ? this.getDate(ts, days) : ts)

    switch (time) {
      case 'start':
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
        break

      case 'end':
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        date.setMilliseconds(999)
        break

      default:
        date.setHours(date.getHours() + this.getTimezone())
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
    }

    return date?.toISOString().substring(0, 10)
  }

  public getTimeString(ts: number, ms?: number) {
    const date = new Date(ms ? this.getTime(ts, ms) : ts)

    return date?.toISOString().substring(11, 12)
  }

  public parseDate(date: string): string {
    if (!date) {
      return ''
    }
    const [day, month, year] = date.split('.')
    if (day && month && year) {
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    } else {
      return ''
    }
  }

  public formatDate(date: string): string {
    if (!date) {
      return ''
    }

    const [year, month, day] = date.split('-')

    return `${day}.${month}.${year}`
  }

  public checkPastXDays(timestamp: string, duration: number) {
    const durations = Date.now() - duration * 24 * 60 * 60 * 1000
    const time = new Date(timestamp).getTime()
    return time > durations
  }
}
