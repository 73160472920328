<template>
  <v-dialog v-if="org" :model-value="!!org" scrollable width="1100" @after-leave="closeDialog()">
    <v-card>
      <v-card-title class="headline">
        {{ dialogTitle }}
        <v-spacer />
        <span class="text-caption mr-2">{{ org.uid }}</span>
      </v-card-title>

      <v-alert v-if="!!error" tile class="px-5 v-sheet--tile" type="error" color="red">
        <v-row class="px-3">
          {{ error }}
        </v-row>
      </v-alert>

      <v-card-text class="pb-0">
        <template v-if="org">
          <h6 class="text-overline mb-4">Organization info</h6>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="organization.name"
                :readonly="!hasAdminAccess"
                :rules="[rules.required]"
                hide-details="auto"
                label="Organization name"
              />
            </v-col>
            <v-col cols="6">
              <v-select v-model="org.type" :items="orgTypes" item-title="text" label="Organization type" readonly>
                <template #append>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon v-bind="props">mdi-pencil-lock-outline</v-icon>
                    </template>
                    Organization type is not editable
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="organization.accountManagerEmail"
                :readonly="!hasAdminAccess"
                :rules="[rules.email]"
                hide-details="auto"
                label="Account manager email"
              />
            </v-col>

            <v-col class="ml-2">
              <v-text-field
                :model-value="org.admins.map((admin) => admin.email).join(', ')"
                label="Admin emails"
                readonly
                append-outer-icon="mdi-pencil-lock-outline"
              >
                <template #append>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-icon v-bind="props">mdi-pencil-lock-outline</v-icon>
                    </template>
                    Admin email is not editable
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col>
              <v-text-field
                v-model="organization.externalId"
                :readonly="!hasAdminAccess || internalSwitch"
                hint="Format: Cust-123456"
                label="NetSuite Customer ID"
                hide-details="auto"
              />
            </v-col>

            <v-col>
              <v-switch
                v-model="internalSwitch"
                :readonly="!hasAdminAccess"
                class="ml-2"
                label="Is an internal test / personal organization"
              />
            </v-col>
          </v-row>

          <v-row class="mx-0 mb-4">
            <v-checkbox
              v-model="organization.permissions"
              :disabled="!hasAdminAccess"
              label="Temperature access"
              color="blue"
              hide-details
              class="mr-4"
              value="temperature_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              :disabled="!hasAdminAccess"
              label="IBI access"
              color="blue"
              hide-details
              class="mr-4"
              value="ibi_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              :disabled="!hasAdminAccess"
              label="30S Hypno access"
              color="blue"
              hide-details
              class="mr-4"
              value="hypno_30s_access"
            />
            <v-checkbox
              v-model="organization.permissions"
              :disabled="!hasAdminAccess"
              label="Full access"
              color="blue"
              hide-details
              class="mr-4"
              value="full_access"
            />
          </v-row>

          <v-alert
            v-if="organization.permissions.includes('full_access') && hasAdminAccess"
            variant="outlined"
            type="warning"
            class="pa-2 text-center"
          >
            You have selected Full access. Other options would be preferred if possible. So please check is this really
            needed
          </v-alert>

          <div class="d-flex justify-space-between">
            <h6 class="text-overline mb-4">Subscriptions</h6>

            <v-btn
              v-if="hasAdminAccess"
              class="mr-n4"
              variant="text"
              color="primary"
              @click="editSubscription = !editSubscription"
            >
              <v-icon class="mr-3">{{ editSubscription ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
              <span class="text-overline">{{ editSubscription ? 'Done Edit' : 'Edit subscriptions' }}</span>
            </v-btn>
          </div>

          <div v-if="!currentSubscription && !futureSubscription" class="my-4">
            <em>No current or future subscriptions</em>
          </div>

          <TeamsSubscription
            v-for="sub in subscriptions"
            :key="sub.subscription_uid"
            :subscription="sub"
            :org="org"
            :default-plan="defaultPlan"
            :edit-subscription="editSubscription"
          />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="!editSubscription && hasAdminAccess"
          class="mt-3 pa-0"
          :disabled="subscriptionWithoutEndDate"
          :dark="!subscriptionWithoutEndDate"
          variant="text"
          color="primary"
          @click="createSubscription()"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          <span style="font-size: 13px">Add New Subscription</span>
        </v-btn>

        <v-spacer />

        <v-btn variant="text" class="mr-2" @click="closeDialog()">Cancel</v-btn>
        <v-btn :disabled="!hasAdminAccess" variant="text" class="mr-2" color="error" @click="confirm = true">
          Delete
        </v-btn>
        <v-btn variant="text" color="primary" :disabled="verifyEdit" @click="saveTeam()">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-if="org" v-model="newSubscriptionModal" width="800">
      <NewSubscription
        :default-plan="defaultPlan"
        :org="org"
        :subscriptions="subscriptions"
        @close="newSubscriptionModal = false"
      />
    </v-dialog>

    <!-- delete organization confirm -->
    <v-dialog v-if="confirm" v-model="confirm" width="500">
      <v-card>
        <v-card-title>Delete {{ org.name }}?</v-card-title>

        <v-card-text>
          This will delete the organization
          <b>{{ org.name }}</b>
          and its
          <b>
            {{ org.coachCount }}
            {{ org.coachCount > 1 ? 'Coaches' : 'Coach' }}
          </b>
        </v-card-text>

        <v-card-actions class="px-4">
          <v-spacer />
          <v-btn variant="text" @click="cancelDelete()">Cancel</v-btn>
          <v-btn variant="text" color="error" :loading="dataWait" @click="confirmDelete()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { emailPattern, orgTypes, ouraTestingExternalId, responseHandler } from '#views/teams/constants'

  import { AppStore, TeamsStore } from '#stores'

  import { Nullable, TeamsOrgSubscription, TeamsOrganizationDetails, TeamsPlan } from '#types'

  @Component
  class EditOrganization extends Vue {
    @Prop() public orgID!: string

    public teamsStore = new TeamsStore()

    public AppsStore = new AppStore()

    public emailPattern = emailPattern

    public error = ''

    public rules = {
      required: (value: string) => !!value || 'Required.',
      email: (value: string) => {
        if (value) {
          return this.emailPattern.test(value) || 'Invalid e-mail.'
        }
        return true
      },
    }

    public orgTypes = orgTypes

    public organization: any = {
      name: '',
      permissions: [],
      externalId: '',
      accountManagerEmail: '',
    }

    public confirm = false
    public internalSwitch = false
    public editSubscription = false
    public newSubscriptionModal = false

    public get rights() {
      return this.AppsStore.activeRights || []
    }

    public get hasAdminAccess() {
      return this.rights.includes('rolesOuraTeamsAdmin')
    }

    public get dataWait() {
      return this.teamsStore.dataWait
    }

    public get adminEmail() {
      return this.org?.admins[0].email
    }

    public get dialogTitle(): string {
      if (this.hasAdminAccess) {
        return 'Edit organization'
      }
      return 'View organization'
    }

    public get defaultPlan() {
      const planName = this.org?.type === 'health_private' || this.org?.type === 'illness_detection' ? 'hrm' : 'teams'

      return this.plans?.find(({ name }) => name === planName)
    }

    public get org(): Nullable<TeamsOrganizationDetails> {
      const org = this.organizationWithDetails

      if (org?.externalId === ouraTestingExternalId) {
        this.internalSwitch = true
      }

      if (org) {
        this.organization = {
          name: org.name,
          type: org.type,
          permissions: org.permissions,
          externalId: org?.externalId,
          accountManagerEmail: org.accountManagerEmail,
        }
      }
      return org
    }

    public get plans(): TeamsPlan[] {
      return this.teamsStore.plans
    }

    public get subscriptions() {
      return this.teamsStore.organizationSubscriptions
    }

    public get subscriptionWithoutEndDate() {
      return this.subscriptions?.some((sub: TeamsOrgSubscription) => !sub.valid_to)
    }

    public get futureSubscription() {
      return this.subscriptions?.find((sub: TeamsOrgSubscription) => {
        const now = new Date().getTime()

        return now < new Date(sub.valid_from).getTime()
      })
    }

    public get currentSubscription() {
      return this.subscriptions?.find((sub: TeamsOrgSubscription) => {
        const now = new Date().getTime()

        return (
          new Date(sub?.valid_from).getTime() <= now && (sub.valid_to ? now <= new Date(sub.valid_to).getTime() : true)
        )
      })
    }

    public get verifyEdit() {
      return (
        !this.hasAdminAccess ||
        !this.org ||
        !this.org.name ||
        !this.org.type ||
        this.dataWait ||
        (!!this.organization.accountManagerEmail && !this.emailPattern.test(this.organization.accountManagerEmail))
      )
    }

    public get organizationWithDetails(): Nullable<TeamsOrganizationDetails> {
      return this.teamsStore.organizationsWithDetails?.[this.orgID] ?? null
    }

    @Watch('internalSwitch')
    protected onInternalSwitchChange() {
      if (this.internalSwitch) {
        this.organization.externalId = ouraTestingExternalId
      } else {
        this.organization.externalId = ''
      }
    }

    public mounted() {
      if (!this.org) {
        this.teamsStore.listOrganizations()
      }

      this.teamsStore.listPlans()
      this.teamsStore.listOrganizationSubscriptions(this.orgID)
      this.teamsStore.getOrganizationDetails({ organizationId: this.orgID })
    }

    public saveTeam() {
      this.error = ''

      const editedOrg = { ...this.org, ...this.organization }

      logEvent(this.$analytics, 'teams_edit_organization', {
        category: `Teams:Edit organization`,
        action: 'Click save team',
        label: 'Click save team',
        page_title: 'Oura Teams admin',
        page_location: window.location.toString().split('?')[0],
      })

      this.teamsStore.saveOrganization(editedOrg).then(
        responseHandler({
          onError: (error) => {
            this.error = error || 'Unknown'
          },
          onSuccess: () => this.$router.push('/teams'),
        }),
      )
    }

    public confirmDelete() {
      logEvent(this.$analytics, 'teams_delete_organization', {
        category: `Teams:Delete organization`,
        action: 'Click confirm delete',
        label: 'Click confirm delete',
        page_title: 'Oura Teams admin',
        page_location: window.location.toString().split('?')[0],
      })

      if (this.org) {
        this.teamsStore.deleteOrganization(this.org?.uid).then(() => {
          this.$router.push('/teams')
          this.confirm = false
        })
      }
    }

    public createSubscription() {
      if (!this.defaultPlan) {
        console.error('Could not find default plan')
        return
      }

      this.newSubscriptionModal = true
    }

    public cancelDelete() {
      this.confirm = false
    }

    public closeDialog() {
      this.confirm = false
      this.$router.push('/teams')
    }
  }
  export default toNative(EditOrganization)
</script>

<style lang="scss" scoped></style>
