<template>
  <div>
    <!-- TODO: Change visible diagnostic to correct ones when all backend diagnostics are done -->

    <DiagnosticsResult
      class="b-8"
      :user="user"
      :ring="ring"
      :sidecar="false"
      :visible-diagnostics="['battery', 'ring-connectivity-diagnostic']"
      :sub-components="diagnosticsSubComponents"
    />

    <template v-if="user && ring">
      <v-alert
        v-show="isGen2"
        tile
        class="mt-8 v-sheet--tile"
        type="error"
        color="red"
        text="Battery data is not available for Gen2 rings"
      />
      <GenericGraph
        class="mb-8"
        title="Battery charge level"
        :graphs="['batteryLevelAssa', 'batteryLifeEstimateAssa', 'spo2usage', 'whrUsage']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryChargeLevelGraphStartDate"
      />

      <GenericGraph
        class="mb-8"
        title="Battery life estimate"
        :graphs="['batteryLifeTrend']"
        :user-uuid="user.uuid"
        :ring="ring"
        :end-date="graphEndDate"
        :start-date="batteryLifeGraphStartDate"
        :autoscale="true"
        :graph-height="400"
      />
    </template>

    <ClientInfo v-if="ring && user" class="mb-8" :member="user" :ring="ring" />
  </div>
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { DiagnosticsSubComponents } from '#views/members/account/DiagnosticsResult.vue'

  import { Member, Ring } from '#types'

  @Component
  class BatteryDetails extends Vue {
    @Prop() public user!: Member
    @Prop() public ring!: Ring
    @Prop() public rings!: Ring[]

    private readonly dateFormat = 'YYYY-MM-DD'

    public graphEndDate = dayjs().format(this.dateFormat)
    public batteryChargeLevelGraphStartDate = dayjs().subtract(14, 'day').format(this.dateFormat)

    public get diagnosticsSubComponents() {
      return [
        DiagnosticsSubComponents.BatteryHealthy,
        DiagnosticsSubComponents.Warranty,
        DiagnosticsSubComponents.Version,
        DiagnosticsSubComponents.SelfTestHealth,
      ]
    }

    public get batteryLifeGraphStartDate() {
      return dayjs(this.ring?.registrationDate).format(this.dateFormat)
    }

    public get isGen2() {
      return this.ring?.hardwareType === 'gen2' || this.ring?.hardwareType === 'gen2m'
    }
  }

  export default toNative(BatteryDetails)
</script>
