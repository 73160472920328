import { AgentReason, ExtraAgentReason, SleepPeriodsItem, SummaryDataReportItem } from '#types'

export const alertColors: any = {
  info: 'blue',
  error: 'red',
  warning: 'yellow',
  success: 'green',
  not_enough_data: 'grey',
}

export const summaryFields = [
  'Battery consumption',
  'Charging slope',
  'Battery health',
  'Bedtime',
  'IBI quality',
  'Sleep Score (v2)',
  'Sleep Score (v1)',
  'Readiness Score (v2)',
  'Readiness Score (v1)',
  'Lowest HR',
  'Temperature',
  'Daily motion',
  'Non-wear minutes',
  'Non-wear periods',
  'Self test result',
  'Field test result',
  'Debug info',
]

export const summaryFieldsDashboardv2 = [
  'Bedtime',
  'IBI quality',
  'Sleep Score (v1)',
  'Sleep Score (v2)',
  'Readiness Score (v1)',
  'Readiness Score (v2)',
  'Lowest HR',
  'Temperature',
  'Daily motion',
  'Non-wear minutes',
  'Non-wear periods',
  'Self test result',
  'Debug info',
]

export const allowSensitiveDataFields = [
  'IBI quality',
  'Temperature',
  'Sleep score (v1)',
  'Sleep score (v2)',
  'Lowest HR',
  'Bedtime',
  'Readiness Score (v1)',
  'Readiness Score (v2)',
  'Daily motion',
  'battery-bedtime',
  'physiology',
  'heartRatePlot',
]

export const quickTimeFields = [
  { name: '1 week', value: -7, key: 'time' },
  { name: '2 weeks', value: -14, key: 'time' },
  { name: '1 month', value: -30, key: 'time' },
  { name: '3 months', value: -90, key: 'time' },
  { name: '6 months', value: -180, key: 'time' },
  { name: '1 year', value: -365, key: 'time' },
  { name: '2 years', value: -730, key: 'time' },
]

export const usersListHeaders: any[] = [
  {
    key: 'email',
    title: 'Email',
    sortable: false,
  },
  {
    key: 'uuid',
    title: 'UID',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Status',
    sortable: false,
  },
  {
    key: 'ringCount',
    title: 'Rings',
    sortable: false,
  },
  {
    key: 'registrationDate',
    title: 'Registered',
    groupable: false,
    align: 'end',
  },
]

export const userListDeleteHeaders: any[] = [
  {
    key: 'deletedStatus',
    title: 'Deleted Status',
    groupable: false,
  },
  {
    key: 'deletedInitiationType',
    title: 'Deleted Type',
    groupable: false,
  },
  {
    key: 'deletedCreatedAt',
    title: 'Request created',
    groupable: false,
  },
  {
    key: 'deletedUpdatedAt',
    title: 'Request updated',
    groupable: false,
  },
]

export const usersListFilters = [
  {
    title: 'Is queued for deletion',
    key: 'queued_for_deletion',
  },
  {
    title: 'JZLog processing disabled',
    key: 'disable_process_jzlog',
  },
  {
    title: 'V1 JZLog processing disabled',
    key: 'disable_process_jzlog_v1',
  },
  {
    title: 'Participating in UCSF study',
    key: 'ucsf_illness_study',
  },
  {
    title: 'Is using the experimental app',
    key: 'experimental_app',
  },
  // {
  //   text: 'Is using the research app',
  //   key: 'research_app',
  // },
  // {
  //   text: 'Is using the debug app',
  //   key: 'debug_app',
  // }
]

export const debugHeaders = [
  {
    title: 'Timestamp',
    key: 'timestamp',
  },
  {
    title: 'User',
    key: 'user_uid',
  },
  {
    title: 'Message',
    key: 'content',
  },
  {
    title: '',
    key: 'actions',
    sortable: false,
  },
]

export const paymentsHeaders = [
  {
    title: 'Reference ID',
    key: 'referenceId',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Total amount',
    key: 'amount',
  },
  {
    title: 'Refund amount',
    key: 'refundAmount',
  },
  {
    title: 'Payment date',
    key: 'effectiveDate',
  },
  {
    title: 'Gateway state',
    key: 'paymentGatewayState',
  },
  {
    title: 'Gateway response',
    key: 'paymentGatewayResponse',
  },
  {
    title: 'Refund status',
    key: 'refundStatus',
  },
  {
    title: '',
    key: 'actions',
  },
]

export const paymentMethodsHeaders = [
  {
    title: 'Is Default',
    key: 'isDefault',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Card type',
    key: 'cardType',
  },
  {
    title: 'Bank ID',
    key: 'bankIdentificationNumber',
  },
  {
    title: 'Card number',
    key: 'cardNumber',
  },
  {
    title: 'Expiration month',
    key: 'expirationMonth',
  },
  {
    title: 'Expiration year',
    key: 'expirationYear',
  },

  {
    title: 'Created on',
    key: 'createdAt',
  },
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
  },
]

export const paymentMethodsHeadersSidecar = [
  {
    title: 'Status',
    key: 'status',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Type',
    key: 'type',
  },
  {
    title: 'Card number',
    key: 'cardNumber',
  },
  {
    title: 'Expiration date',
    key: 'expirationYear',
  },
  {
    title: 'Bank ID',
    key: 'bankIdentificationNumber',
  },
  {
    title: 'Created on',
    key: 'createdAt',
  },
  {
    title: 'Actions',
    key: 'actions',
  },
]

export const refundsHeaders = [
  { title: 'ID', key: 'id' },
  { title: 'Marked For Submission On', key: 'markedForSubmissionOn' },
  { title: 'Reason Code', key: 'reasonCode' },
  { title: 'Refund Transaction Time', key: 'refundTransactionTime' },
  { title: 'Status', key: 'status' },
]

export const invoiceHeaders = [
  {
    key: 'date',
    title: 'Invoice date',
  },
  {
    key: 'status',
    title: 'Status',
  },
  {
    key: 'amount',
    title: 'Amount',
  },
  {
    key: 'balance',
    title: 'Balance',
  },
  {
    key: 'view-invoice',
    title: 'Invoice (PDF)',
  },
]

export const creditMemoHeaders = [
  {
    title: 'Issue start date',
    key: 'issueStartDate',
  },
  {
    title: 'Amount',
    key: 'amount',
  },
  {
    title: 'Unapplied amount',
    key: 'unappliedAmount',
  },
  {
    title: 'is compensation',
    key: 'isCompensation',
  },
  {
    title: 'Compensation reason',
    key: 'compensationReason',
  },
  {
    title: 'Compensation escalation reason',
    key: 'compensationEscalationReason',
  },
  {
    title: '',
    key: 'view-credit-memo',
  },
]

export const reproductiveHormoneAnswerHeaders = [
  { title: 'Day', key: 'day' },
  { title: 'Fertility treatments', key: 'fertilityTreatments' },
  { title: 'Hormonal contraception', key: 'hormonalContraception' },
  { title: 'Hormone replacement therapy', key: 'hormoneReplacementTherapy' },
  { title: 'Other', key: 'other' },
  { title: 'Prefer not to answer', key: 'preferNotToAnswer' },
]

export const featuresHeaders = [
  {
    title: 'Name',
    key: 'name',
    class: 'pl-6',
    cellClass: 'pl-6',
  },
  {
    title: 'Status',
    key: 'enabled',
  },
  {
    title: 'Extra info',
    key: 'extraInfo',
    class: 'wrapText',
  },
]

export const fileTypes = [
  {
    text: 'JZLog files',
    value: 'jzlog',
  },
  {
    text: 'ORB files',
    value: 'orb',
  },
  {
    text: 'Debug logs',
    value: 'debuglog',
    types: ['debuglog.zip'],
  },
  {
    text: "Latest zip's",
    value: 'latest',
    types: ['latest.zip'],
  },
  {
    text: 'SQLite files',
    value: 'sqlite',
    types: ['sqlite', 'sqlite.zip', 'nexus-sqlite.zip'],
  },
  {
    text: 'Realm files',
    value: 'realm',
    types: [
      'store.realm',
      'assa.realm',
      'full.realm',
      'timeseries.realm',
      'store.realm.zip',
      'assa.realm.zip',
      'full.realm.zip',
      'timeseries.realm.zip',
      'nexus-realm.zip',
    ],
  },
]

export const fileHeaders = [
  {
    title: '',
    key: 'key',
    sortable: false,
  },
  {
    title: 'Last Modified',
    key: 'lastModified',
    sortable: false,
  },
  {
    title: 'OS',
    key: 'os',
    sortable: false,
  },
  {
    title: 'Ring',
    key: 'ring',
    sortable: false,
  },
  {
    title: 'Task',
    key: 'task',
    sortable: false,
  },
  {
    title: 'Size',
    key: 'size',
    sortable: false,
  },
  {
    title: 'File',
    key: 'file',
    sortable: false,
  },
  {
    title: '',
    key: 'type',
    sortable: false,
  },
]

export const eventsHeaders = [
  {
    title: '',
    key: 'icon',
    sortable: false,
  },
  {
    title: 'Event',
    key: 'name',
    sortable: false,
  },
  {
    title: 'Info',
    key: 'info',
    sortable: false,
  },
  {
    title: 'Notes',
    key: 'note',
    sortable: false,
  },
  {
    title: 'Time',
    key: 'time',
    sortable: false,
  },
  {
    title: '',
    key: 'data-table-expand',
    sortable: false,
  },
]

export const orbsHeaders = [
  {
    width: 20,
    title: '',
    key: 'key',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 140,
    title: 'Label',
    key: 'label',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 40,
    title: 'Firmware Version',
    key: 'fw',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 20,
    title: 'Collection ID',
    key: 'collectionId',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 180,
    title: 'Recording Started',
    key: 'started',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 80,
    title: 'Size',
    key: 'binarySize',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 160,
    title: 'Stream UUID',
    key: 'streamUuid',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 180,
    title: 'Uploaded',
    key: 'uploadedAt',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
  {
    width: 180,
    title: 'Recording Ended',
    key: 'ended',
    cellClass: 'text-no-wrap',
    filterable: false,
  },
]

export const refundReasonFields = [
  // TODO: Commented out all headers and dividers since vuetify3 v-select doesn't support them. Add back when supported.

  // [
  //  { header: 'SUBSCRIPTIONS' },
  {
    text: 'Subscription canceled',
    value: 'Subscription canceled',
  },
  {
    text: 'Subscription expired one month early',
    value: 'Subscription expired one month early',
  },
  {
    text: 'Subscription not canceled after ring returned',
    value: 'Subscription not canceled after ring returned',
  },
  {
    text: 'Lifetime member charged',
    value: 'Lifetime member charged',
  },
  {
    text: 'Overcharged',
    value: 'Overcharged',
  },
  //  { divider: true },
  // ],
  // [
  //   { header: 'BATTERY' },
  {
    text: 'Unsatisfied with battery life',
    value: 'Unsatisfied with battery life',
  },
  {
    text: 'Battery failed',
    value: 'Battery failed',
  },
  {
    text: 'Ring not charging',
    value: 'Ring not charging',
  },
  //   { divider: true },
  // ],
  // [
  //   { header: 'DATA ISSUES' },
  {
    text: 'Data loss',
    value: 'Data loss',
  },
  {
    text: 'Data loss after changing mobile device',
    value: 'Data loss after changing mobile device',
  },
  {
    text: 'Ring data syncing error',
    value: 'Ring data syncing error',
  },
  {
    text: 'Google fit syncing error',
    value: 'Google fit syncing error',
  },
  {
    text: 'Naps not recorded',
    value: 'Naps not recorded',
  },
  {
    text: 'Heart rate feature error',
    value: 'Heart rate feature error',
  },
  {
    text: 'Workout heart rate feature error',
    value: 'Workout heart rate feature error',
  },
  {
    text: 'Inaccurate/Missing data',
    value: 'Inaccurate/Missing Data',
  },
  {
    text: 'GPS not tracking',
    value: 'GPS not tracking',
  },
  //   { divider: true },
  // ],
  // [
  //   { header: 'PERFORMANCE ISSUES' },
  {
    text: 'Connection issues',
    value: 'Connection issues',
  },
  {
    text: 'App crashing',
    value: 'App crashing',
  },
  {
    text: 'Troubleshooting resolution delay',
    value: 'Troubleshooting resolution delay',
  },
  //    { divider: true },
  // ],
  // [
  //  { header: 'OTHER' },
  {
    text: 'Warranty exchange',
    value: 'Warranty exchange',
  },
  {
    text: 'Upgrade/Downgrade',
    value: 'Upgrade/Downgrade',
  },
  {
    text: 'Loss of ring',
    value: 'Loss of ring',
  },
  {
    text: 'Escalations',
    value: 'Escalations',
  },
  {
    text: 'Shipping delay',
    value: 'Shipping delay',
  },
  {
    text: 'Product not delivered',
    value: 'Product not delivered',
  },
  {
    text: 'Tech support bug',
    value: 'Tech support bug',
  },
  {
    text: 'Size exchange',
    value: 'Size exchange',
  },
  {
    text: 'Product Safety Issue',
    value: 'Product Safety Issue',
  },
  // ],
]

export const compensationReasons = [
  //TODO: Commented out all headers and dividers since vuetify3 v-select doesn't support them. Add back when supported.
  //  [
  //   { header: 'SUBSCRIPTIONS' },
  {
    text: 'Subscription expired one month early',
    value: 'Subscription expired one month early',
  },
  {
    text: 'Overcharged',
    value: 'Overcharged',
  },
  //   { divider: true },
  // ],
  // [
  //   { header: 'BATTERY' },
  {
    text: 'Unsatisfied with battery life',
    value: 'Unsatisfied with battery life',
  },
  {
    text: 'Battery failed',
    value: 'Battery failed',
  },
  {
    text: 'Business development efforts',
    value: 'Business Development Efforts',
  },
  {
    text: 'Ring not charging',
    value: 'Ring not charging',
  },
  //  { divider: true },
  //],
  // [
  //  { header: 'DATA ISSUES' },
  {
    text: 'Data loss',
    value: 'Data loss',
  },
  {
    text: 'Data loss after changing mobile device',
    value: 'Data loss after changing mobile device',
  },
  {
    text: 'Ring data syncing error',
    value: 'Ring data syncing error',
  },
  {
    text: 'Google fit syncing error',
    value: 'Google fit syncing error',
  },
  {
    text: 'Naps not recorded',
    value: 'Naps not recorded',
  },
  {
    text: 'Heart rate feature error',
    value: 'Heart rate feature error',
  },
  {
    text: 'Workout heart rate feature error',
    value: 'Workout heart rate feature error',
  },
  {
    text: 'Inaccurate/Missing data',
    value: 'Inaccurate/Missing Data',
  },
  {
    text: 'GPS not tracking',
    value: 'GPS not tracking',
  },
  //  { divider: true },
  //],
  //[
  // { header: 'PERFORMANCE ISSUES' },
  {
    text: 'Connection issues',
    value: 'Connection issues',
  },
  {
    text: 'App crashing',
    value: 'App crashing',
  },
  {
    text: 'Troubleshooting resolution delay',
    value: 'Troubleshooting resolution delay',
  },
  //    { divider: true },
  //  ],
  // [
  //  { header: 'OTHER' },
  {
    text: 'Warranty exchange',
    value: 'Warranty exchange',
  },
  {
    text: 'Upgrade/Downgrade',
    value: 'Upgrade/Downgrade',
  },
  {
    text: 'Loss of ring',
    value: 'Loss of ring',
  },
  {
    text: 'Escalations',
    value: 'Escalations',
  },
  {
    text: 'Shipping delay',
    value: 'Shipping delay',
  },
  {
    text: 'Product not delivered',
    value: 'Product not delivered',
  },
  {
    text: 'Tech support bug',
    value: 'Tech support bug',
  },
  {
    text: 'Size exchange',
    value: 'Size exchange',
  },
  {
    text: 'Member win back',
    value: 'Member Win Back',
  },
  // ],
]

export const subscriptionEventsHeaders = [
  { title: 'Subscription ID', key: 'subscriptionId' },
  {
    title: 'Created at',
    key: 'createdAt',
  },
  {
    title: 'Event type',
    key: 'type',
  },
  {
    title: 'Reason',
    key: 'reason',
  },
  {
    title: 'Account ID',
    key: 'accountId',
  },
]

export const auditLogsHeaders = [
  { title: 'Subscription ID', key: 'subscriptionId' },
  { title: 'Date and time', key: 'createdAt' },
  { title: 'Event type', key: 'eventType' },
  { title: 'Details', key: 'details' },
  { title: 'User', key: 'email' },
]
export const extensionReasons = [
  {
    text: 'Warranty exchange',
    value: 'Warranty exchange',
  },
  {
    text: 'Upgrade/Downgrade',
    value: 'Upgrade/Downgrade',
  },
  {
    text: 'Loss of ring',
    value: 'Loss of ring',
  },
  {
    text: 'Escalations',
    value: 'Escalations',
  },
]

export const appActions = [
  {
    title: 'Backup all data',
    value: 'backup',
  },
  {
    title: 'Navigate to settings',
    value: 'navigate-settings',
  },
]

export const appActionTexts: any = {
  'backup': 'Oura support wants to backup all your data. Do you want to proceed?',
  'navigate-settings': 'Oura support wants to show where you can find the settings, proceed?',
}

export const timelineMaxChipLength = 15

// Used to highlight charging slope values and diagnostic if charging slope is above this threshold
export const chargingSlopeFailThreshold = 0.22

// Used to highlight fuel gauge health values fuel gauge health is below this threshold
export const fgHealthFailThreshold = 90

export const userSearchMaxResults = 100
export const userSearchFirstPage = 1

export const defaultTimelineStartOffset = -30

// Generic graph related constants
export const graphDayThreshold = 10
export const genericGraphChipColor = '#f62263'

export const plotlyIconFullscreen = {
  width: 448,
  height: 512,
  path: 'M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z',
}
export const plotlyIconToggleHover = {
  width: 35,
  height: 35,
  path: 'M29.375 28.375h-24.313c-0.75 0-1.438-0.625-1.438-1.375v-24.375c0-0.563-0.438-1-1-1s-1 0.438-1 1v24.375c0 1.875 1.5 3.375 3.438 3.375h24.375c0.563 0 1-0.438 1-1s-0.5-1-1.063-1zM10.938 22.063c0 1-0.813 1.75-1.75 1.75s-1.75-0.75-1.75-1.75c0-0.938 0.813-1.688 1.75-1.688s1.75 0.75 1.75 1.688zM17.25 12.813c0 1-0.75 1.75-1.75 1.75-0.938 0-1.688-0.75-1.688-1.75 0-0.938 0.75-1.688 1.688-1.688 1 0 1.75 0.75 1.75 1.688zM19.438 19.625c0 1-0.75 1.75-1.688 1.75-1 0-1.75-0.75-1.75-1.75 0-0.938 0.75-1.688 1.75-1.688 0.938 0 1.688 0.75 1.688 1.688zM24.313 14.063c0 0.938-0.75 1.688-1.75 1.688-0.938 0-1.688-0.75-1.688-1.688s0.75-1.75 1.688-1.75c1 0 1.75 0.813 1.75 1.75zM27 7.5c0 0.938-0.813 1.688-1.75 1.688s-1.75-0.75-1.75-1.688c0-0.938 0.813-1.75 1.75-1.75s1.75 0.813 1.75 1.75z',
}

// Files larger than 500 MB cannot be anonymized
export const anonymizedFileDownloadLimitBytes = 1024 * 1024 * 500

export const factoryResetStatuses: any = {
  true: 'Reset successfully',
  false: 'Unable to reset',
  none: 'Not reset',
}

export const debugLogSearchMinLength = 3
export const debugLogSearchMaxLength = 255

export const graphMaxTotalWeight = 150_000
export const graphSingleDayWeight = 1.5

export const graphMaxDays = 365 * 3 // 3 years

export const graphDefaultEarliestStartDate = `${new Date().getFullYear() - 2}-01-01`

export const deleteStatus = {
  INITIAL: 'initial',
  PENDING: 'pending',
  REQUESTED: 'requested',
}

const agentCommonExtraReasons: ExtraAgentReason[] = [
  { reason: 'Sleep' },
  { reason: 'Movement' },
  { reason: 'Reproductive' },
  { reason: 'Stress' },
  { reason: 'Heart Health' },
  { reason: 'Tools & Platforms' },
  { reason: 'Behavioral Science' },
  { reason: 'AI' },
  { reason: 'Metabolic Health' },
  { reason: 'Oura research project', requiresTextExplanation: true, textExplanationLabel: 'Project Name' },
  { reason: 'OR4' },
  { reason: 'Other', requiresTextExplanation: true },
]

export const agentReasons: AgentReason[] = [
  {
    mainReason: 'Access requested on behalf of another member. Consent received member in cc-field',
    requiresTextExplanation: true,
    textExplanationLabel: 'Ticket number',
  },
  { mainReason: 'Business development efforts' },
  {
    mainReason: 'Investigating warranty, to identify which ring member has in use',
    requiresTextExplanation: true,
    textExplanationLabel: 'RA number',
  },
  {
    mainReason: 'Membership action required due to return (Tender RMA Process)',
    requiresTextExplanation: true,
    textExplanationLabel: 'RA number',
  },
  { mainReason: 'Employee lifetime upgrade' },
  {
    mainReason: 'Investigating membership or order issues',
    extraReasons: [
      { reason: 'Membership Billing ' },
      { reason: 'Returns' },
      { reason: 'Fraud' },
      { reason: 'Chargeback investigation ' },
    ],
  },
  { mainReason: 'B2B Membership Issue investigations' },
  { mainReason: 'B2B membership renewal' },
  { mainReason: 'B2B SaaS issue investigation' },
  {
    mainReason: 'B2B support request on behalf of end user (no direct consent from the end user).',
  },
  { mainReason: 'Account deletion investigation' },
  {
    mainReason: 'Investigation due to Jira ticket / Project',
    requiresTextExplanation: true,
    textExplanationLabel: 'Jira Ticket or Project Name',
  },
  {
    mainReason: 'Development - new feature',
    extraReasons: agentCommonExtraReasons,
  },
  {
    mainReason: 'Improvement - existing feature',
    extraReasons: agentCommonExtraReasons,
  },
  {
    mainReason: 'Troubleshooting (employee data)',
    extraReasons: agentCommonExtraReasons,
  },
  {
    mainReason: 'Troubleshooting (customer data)',
    extraReasons: agentCommonExtraReasons,
  },
  {
    mainReason: 'Troubleshooting Hardware/Firmware',
    requiresTextExplanation: true,
  },
  { mainReason: 'Detailed data analysis', extraReasons: agentCommonExtraReasons },
  {
    mainReason: 'Data Privacy issues',
    requiresTextExplanation: true,
  },
  { mainReason: 'SCC investigation' },
  { mainReason: 'Shipping investigation' },
  { mainReason: 'Research study participant' },
  {
    mainReason: 'Other reason',
    requiresTextExplanation: true,
  },
]

export const defaultTextExplanationLabel = 'Please provide additional details'

export const maxPinnedDiagnosticsCount = 5

export const summaryHeaders: {
  title: string
  key: keyof SummaryDataReportItem
  sortable?: boolean
  cellProps?: { noWrap?: boolean }
}[] = [
  {
    title: 'Date',
    key: 'day',
  },
  {
    title: 'Timezone',
    key: 'timezone',
  },
  {
    title: 'Bedtime Start',
    key: 'bedtimeStart',
    cellProps: {
      noWrap: true,
    },
  },
  {
    title: 'Bedtime End',
    key: 'bedtimeEnd',
    cellProps: {
      noWrap: true,
    },
  },
  {
    title: 'Nightly IBI Quality',
    key: 'nightlyIbiQuality',
  },
  {
    title: 'DHR IBI Quality',
    key: 'dhrIbiQuality',
  },
  {
    title: 'Sleep Score',
    key: 'sleepScore',
  },
  {
    title: 'Readiness Score',
    key: 'readinessScore',
  },
  {
    title: 'Lowest HR',
    key: 'lowestHR',
  },
  {
    title: 'Temperature',
    key: 'temperature',
  },
  {
    title: 'Daily Motion',
    key: 'dailyMotion',
  },
  {
    title: 'Non-wear Minutes',
    key: 'nonWearMinutes',
  },
  {
    title: 'Non-wear Minutes During Bedtime',
    key: 'nonWearMinutesDuringBedtime',
  },
  {
    title: 'Non-wear Periods',
    key: 'nonWearPeriods',
  },
  {
    title: 'Ring Serial Number',
    key: 'ringSerialNumber',
  },
  {
    title: 'Self Test',
    key: 'selfTestSuccess',
  },
  {
    title: 'Field Test',
    key: 'fieldTestSuccess',
  },
  {
    title: 'Debug events',
    key: 'events',
  },
  // Still missing non-wear during bedime, non wear during 24h and debug info
]
const nonSensitiveSummaryDataKeys: (keyof SummaryDataReportItem)[] = [
  'day',
  'dailyMotion',
  'nonWearMinutes',
  'nonWearMinutesDuringBedtime',
  'nonWearPeriods',
  'events',
  'selfTestDetails',
  'selfTestSuccess',
  'fieldTestDetails',
  'fieldTestSuccess',
  'ringSerialNumber',
]

export const nonSensitiveSummaryHeaders = summaryHeaders.filter((header: any) =>
  nonSensitiveSummaryDataKeys.includes(header.key),
)

export const summaryHeaderTooltips: { [key: string]: string } = {
  timezone: 'Timezone at the end of the day',
}

export const sleepPeriodsHeaders: {
  title: string
  key: keyof SleepPeriodsItem
  sortable?: boolean
  cellProps?: { noWrap?: boolean }
}[] = [
  {
    title: 'Date',
    key: 'date',
  },
  {
    title: 'Bedtime Start',
    key: 'bedtimeStart',
    cellProps: {
      noWrap: true,
    },
  },
  {
    title: 'Bedtime End',
    key: 'bedtimeEnd',
    cellProps: {
      noWrap: true,
    },
  },
  {
    title: 'Duration',
    key: 'duration',
  },
  {
    title: 'Type',
    key: 'type',
  },
]
