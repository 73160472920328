<template>
  <v-card>
    <v-card-title>
      <div class="text-overline">Backup files</div>
    </v-card-title>

    <v-card-text>
      <v-btn-toggle v-model="type" mandatory color="primary" class="mb-4" style="min-width: 100%" variant="outlined">
        <v-btn v-for="(t, i) in types" :key="i" :text="t.text" :value="i" class="flex-grow-1" />
      </v-btn-toggle>

      <v-data-table
        v-if="headers"
        :loading="dataWait"
        :items="files"
        :headers="headers"
        :items-per-page="100"
        :sort-by="[{ key: 'lastModified', order: 'desc' }]"
        item-key="index"
        disable-pagination
      >
        <template #[`item.lastModified`]="{ item }">
          <div v-if="item.lastModified" class="text-no-wrap">
            {{ formatDateTime(item.lastModified, 'HH:mm:ss - DD MMM YYYY') }}
          </div>
        </template>
        <template #[`item.os`]="{ item }">
          <div class="text-no-wrap">
            {{ fileOS(item) }}
          </div>
        </template>
        <template #[`item.ring`]="{ item }">
          <div class="text-no-wrap">
            {{ ringLabel(item.key) }}
          </div>
        </template>
        <template #[`item.task`]="{ item }">
          <div class="text-no-wrap" :title="type === 1 ? item.key.split('/')[3] : ''">
            {{ fileTask(item) }}
          </div>
        </template>
        <template #[`item.size`]="{ item }">
          <div class="text-no-wrap">
            {{ formatFileSize(item.size!) }}
          </div>
        </template>
        <template #[`item.file`]="{ item }">
          <HoverCopy :data="fileName(item)" :message="'Copy filename to clipboard'" />
        </template>
        <template #[`item.key`]="{ item }">
          <div style="white-space: nowrap">
            <template v-if="sensitiveDataToggleSupported(item)">
              <v-btn
                v-if="isSensitiveDataVisibleForFile(item)"
                icon
                dark
                color="green"
                @click="hideSensitiveData(item)"
              >
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-icon dark v-bind="props">mdi-eye</v-icon>
                  </template>
                  <span>Sensitive data will be present</span>
                </v-tooltip>
              </v-btn>
              <v-btn v-else icon dark color="red" @click="showSensitiveData(item)">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-icon dark v-bind="props">mdi-eye-off</v-icon>
                  </template>
                  <span>Sensitive data will be anonymized</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-btn icon @click.stop="downloadFile(item)">
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </div>
        </template>
        <template #[`item.type`]="{ item }">
          <div v-if="false">
            {{ item.type }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { anonymizedFileDownloadLimitBytes, fileHeaders, fileTypes } from '#views/members/constants'

  import { formatFileSize } from '#utils/files/formatFileSize'

  import { FilesStore } from '#stores'

  import { DataFile } from '#types'

  @Component
  class DataFilesListing extends mixins(DateTime) {
    @Prop() public env!: string
    @Prop() public uuid!: string
    @Prop() public rings!: any[]

    public filesStore = new FilesStore()

    /**
     * Currently active type, index of types array.
     *
     * Value is -1 if type is not supported (return value of findIndex if value is not found).
     */
    public type = 0
    public activeTab = 0
    public realmsTab: string = ''

    public types = fileTypes
    public headers = fileHeaders

    public get realmTypes() {
      return (this.types || []).filter((t) => t?.value?.includes('realm'))
    }

    public get files() {
      if (!this.types || this.type < 0 || !this.types[this.type]) {
        return []
      }

      const value = this.types[this.type].value
      const types = this.types[this.type].types || [value]

      return (this.filesStore.files || []).filter((f) => f.type === value || types.find((t) => f.key.endsWith(t)))
    }

    public get dataWait() {
      return this.filesStore.dataWait
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.listFiles()
    }

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Data Files',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public mounted() {
      if (this.uuid) {
        this.listFiles()
      }

      logEvent(this.$analytics, 'page_view', {
        page_title: 'Backup files',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public fileOS(file: DataFile) {
      const os = file.key.split('/')[2]

      return os === 'android' ? 'Android' : os === 'ios' ? 'iOS' : 'Not specified'
    }

    public isSensitiveDataVisibleForFile(file: DataFile) {
      // If file size is over supported anonymized size limit....
      if (file.size && file.size > anonymizedFileDownloadLimitBytes) {
        return true
      }

      const sensitiveStatus = this.filesStore.sensitiveDownloadChoices
      return sensitiveStatus.includes(file.key)
    }

    public sensitiveDataToggleSupported(file: DataFile) {
      // If file size is over supported anonymized size limit....
      if (file.size && file.size > anonymizedFileDownloadLimitBytes) {
        return false
      }

      // We could have list of file types which support sensitive data toggling and we could use Enum here to
      // have fixed list of file types.
      return file.type === 'jzlog'
    }

    /**
     * Show sensitive data on downloaded file
     * @param file DataFile
     */
    public showSensitiveData(file: DataFile) {
      this.filesStore.updateDownloadPreference({ downloadSensitiveData: true, file: file })
    }

    /**
     * Hide sensitive data on downloaded file
     * @param file DataFile
     */
    public hideSensitiveData(file: DataFile) {
      this.filesStore.updateDownloadPreference({ downloadSensitiveData: false, file: file })
    }

    public handleRealmsTabClick(value: string) {
      this.type = this.types.findIndex((type) => type.value === value)
      this.activeTab = this.type
    }

    public fileName(file: DataFile) {
      // check if it's SQLite files tab
      if (this.types?.[this.type]?.value === 'sqlite') {
        return file.key.split('/').slice(4).join('/').split('_').slice(1).join('_')
      }
      return (
        (file.bucket === 'ring-stream'
          ? file.key.split('/').slice(3).join('/')
          : file.key.split('/').slice(4).join('/')) +
        (file.type === 'jzlog' && file.bucket === 'ring-stream' ? '.jzlog' : file.type === 'orb' ? '.orb' : '')
      )
    }

    public fileTask(file: DataFile) {
      const isRingStreamBucket = file.bucket === 'ring-stream'

      switch (file.type) {
        case 'zip':
          // check if fileType is SQLite files tab
          if (this.type === 1) {
            return '...' + file.key.split('/')[3].slice(-5)
          }
          return isRingStreamBucket ? file.key.split('/')[2] : file.key.split('/')[3].replace('process', 'archive')

        case 'jzlog':
          return isRingStreamBucket ? 'process_jzlog' : 'process_jzlog_v1'

        default:
          return 'full'
      }
    }

    public formatFileSize(fileSize: number) {
      return formatFileSize(fileSize)
    }

    public ringLabel(key: string) {
      const parts = key.split('_')

      const mac = parts[parts.length - 1].split('.')[0]

      const index = this.rings.findIndex(
        (ring) => ring.macAddress.replace(/:/g, '').toUpperCase() === mac.toUpperCase(),
      )

      if (index === 0) {
        return `Current (${this.rings[index].macAddress})`
      } else if (index !== -1) {
        return `Old ${index} (${this.rings[index].macAddress})`
      }

      return 'Not specified'
    }

    public downloadFile(file: DataFile) {
      file.env = this.env
      file.name = this.fileName(file).includes(this.uuid)
        ? this.fileName(file).split('/').pop()
        : this.uuid + '-' + this.fileName(file).split('/').pop()
      file.anonymizeJzlog = !this.isSensitiveDataVisibleForFile(file)
      this.filesStore.downloadFile(file)

      if (file.type === 'jzlog') {
        logEvent(this.$analytics, 'data_download_jzlog', {
          category: 'File:downloadFile',
          action: 'Click download Jzlog list',
          label: 'Click download Jzlog list',
          page_title: 'Oura user - Data Files tab',
          page_location: window.location.toString().split('?')[0],
        })
      }
    }

    private listFiles() {
      if (this.uuid) {
        this.filesStore.listFiles(this.uuid)
      }
    }
  }

  export default toNative(DataFilesListing)
</script>
