import { factoryResetStatuses } from '#views/members/constants'

import { parseSerialNumber } from '#utils/utils'

import { DeviceReturnInfo, ReturnAuthorizationInfo, WarrantyData, WarrantyDataOrder } from '#types'
import {
  Nullable,
  WarrantyReturnInfo,
  WarrantyReturnInfoReplacedBy,
  WarrantyReturnInfoReplacementFor,
  WarrantyReturnInfoReturnAuthorization,
  WarrantyReturnInfoReturnedItem,
} from '#types'

function extractSingleWarrantyReturnInfo(
  deviceType: 'Ring' | 'Charger',
  deviceReturnInfo: DeviceReturnInfo,
  warrantyNotes: Nullable<string> = null,
  replacementForDeviceReturnInfo: Nullable<DeviceReturnInfo> = null,
  replacedByOrder: Nullable<WarrantyDataOrder> = null,
): WarrantyReturnInfo {
  let ra: Nullable<WarrantyReturnInfoReturnAuthorization> = null
  let replacementFor: Nullable<WarrantyReturnInfoReplacementFor> = null
  let replacedBy: Nullable<WarrantyReturnInfoReplacedBy> = null
  const deviceRA: Nullable<ReturnAuthorizationInfo> = deviceReturnInfo.returnAuthorization

  if (deviceReturnInfo.replaces) {
    const replacementTxId = replacementForDeviceReturnInfo?.returnAuthorization?.transactionId ?? null
    replacementFor = {
      returnAuthorizationTransactionId: replacementTxId,
      serialNumberTitle: deviceType + ' serial number',
      serialNumber: deviceReturnInfo.replaces,
      serialNumberParsed: parseSerialNumber(deviceReturnInfo.replaces),
    }
  }

  if (deviceReturnInfo.replacedBy) {
    const replacedByTxId = replacedByOrder?.transactionId ?? null
    replacedBy = {
      orderTransactionId: replacedByTxId,
      serialNumberTitle: deviceType + ' serial number',
      serialNumber: deviceReturnInfo.replacedBy,
      serialNumberParsed: parseSerialNumber(deviceReturnInfo.replacedBy),
    }
  }

  if (deviceRA && deviceRA.transactionId) {
    const returnedItems: WarrantyReturnInfoReturnedItem[] = []

    if (deviceRA.returnedItems && deviceRA.returnedItems.length > 0) {
      for (const item of deviceRA.returnedItems) {
        let factoryResetStatus: Nullable<string> = null
        let factoryResetReason: Nullable<string> = null
        if (
          item.factoryReset?.status &&
          Object.prototype.hasOwnProperty.call(factoryResetStatuses, item.factoryReset.status)
        ) {
          factoryResetStatus = factoryResetStatuses[item.factoryReset?.status] ?? null
        }
        if (item.factoryReset?.reason && item.factoryReset.reason.length > 0) {
          factoryResetReason = item.factoryReset.reason
        }

        returnedItems.push({
          itemType: item.itemType[0].toUpperCase() + item.itemType.slice(1),
          returnNumber: item.returnNumber,
          handlerEmail: item.handlerEmail ?? null,
          factoryResetStatus: factoryResetStatus,
          factoryResetReason: factoryResetReason,
          returnedAt: item.createdAt,
        })
      }
    }

    ra = {
      transactionId: deviceRA.transactionId,
      reason: deviceRA.reason,
      status: deviceRA.status,
      timestamp: deviceRA.timestamp,
      lastModifiedAt: deviceRA.lastModified,
      returnedItems: returnedItems,
    }
  }

  return {
    replacementFor: replacementFor,
    replacedBy: replacedBy,
    returnAuthorization: ra,
    warrantyNotes: warrantyNotes,
  }
}

/**
 * Extract warranty return info(s) from warranty data
 *
 * Improvement ideas:
 * - Handle case where we both ring and charger RA are empty? Are there any?
 */
export function extractWarrantyReturnInfoFromWarrantyData(
  warrantyData: WarrantyData,
  ringWarrantyNotes: Nullable<string> = null,
  chargerWarrantyNotes: Nullable<string> = null,
  warrantyDataReplacementFor: Nullable<WarrantyData> = null,
  warrantyDataReplacedBy: Nullable<WarrantyData> = null,
): WarrantyReturnInfo[] {
  const ringReturn: DeviceReturnInfo = warrantyData.ringReturn
  const chargerReturn: DeviceReturnInfo = warrantyData.chargerReturn
  const returnInfos: WarrantyReturnInfo[] = []
  const raIdsIdentical =
    ringReturn.returnAuthorization?.transactionId == chargerReturn.returnAuthorization?.transactionId
  const chargerReturnRAhasData = !!chargerReturn.returnAuthorization
  const ringReturnRAhasData = !!ringReturn.returnAuthorization
  const ringReturnReplacesHasData = ringReturn.replaces && ringReturn.replaces.length > 0

  if (ringReturnRAhasData || ringReturnReplacesHasData) {
    const replacedByOrder = warrantyDataReplacedBy?.order ?? null
    const replacementForRingReturnInfo = warrantyDataReplacementFor?.ringReturn ?? null
    returnInfos.push(
      extractSingleWarrantyReturnInfo(
        'Ring',
        ringReturn,
        ringWarrantyNotes,
        replacementForRingReturnInfo,
        replacedByOrder,
      ),
    )
    if (raIdsIdentical) {
      return returnInfos
    }
  }

  // need to handle replaced by / replacement for cases for chargers also.
  if (chargerReturnRAhasData) {
    returnInfos.push(extractSingleWarrantyReturnInfo('Charger', chargerReturn, chargerWarrantyNotes))
  }
  return returnInfos
}
