<template>
  <v-alert
    v-if="showAlert"
    tile
    border="start"
    elevation="2"
    :variant="!alert.isHidden ? 'outlined' : 'tonal'"
    :type="alertSymbol.type"
    :icon="alertSymbol.icon"
    :color="alertSymbol.color"
    style="
      position: relative;
      height: 100%;
      padding: 15px 0 12px 6px;
      border: 0 !important;
      border-radius: 0 !important;
      box-shadow:
        rgba(0, 0, 0, 0.2) 0px 0px 0px -1px,
        rgba(0, 0, 0, 0.14) 0px 0px 1px,
        rgba(0, 0, 0, 0.12) 1px 0px 1px !important;
    "
  >
    <v-row align="center" class="flex-nowrap">
      <v-col class="flex-grow-1 flex-shrink-1" style="white-space: nowrap !important" cols="8">
        <span class="alert-text" style="white-space: normal">{{ alert?.message }}</span>
        <span
          v-if="alert.extraInfo?.items?.length"
          small
          class="mb-1 ml-2 details-button"
          @click="
            (updateExtraInfo(extraInfo === index ? null : index),
            (expand = !expand),
            logAnalyticsEvent($analytics, `${sidecar ? 'sidecar' : 'dashboard'}_diagnostics_info`, {
              action: 'Click i icon',
              category: `Alert: ${sidecar ? 'Sidecar' : 'Dashboard'}`,
              label: `Click i (info) Diagnostics ${sidecar ? 'Sidecar' : 'Dashboard'}`,
            }))
          "
        >
          Details
        </span>
      </v-col>

      <v-col class="flex-shrink-0 flex-grow-0" style="text-align: right" cols="4">
        <v-tooltip v-if="sidecar && alertMacro.id" location="top">
          <template #activator="{ props }">
            <v-btn
              v-if="alertMacro.id"
              color="blue-lighten-1"
              variant="text"
              size="small"
              class="sidecar_left"
              target="_blank"
              v-bind="props"
              @click.stop="applyMacro(alertMacro.id)"
            >
              <v-icon>mdi-arrow-left-bottom-bold</v-icon>
            </v-btn>
          </template>
          {{ alertMacro.title ? alertMacro.title : 'Apply macro' }}
        </v-tooltip>
        <v-tooltip v-else location="top">
          <template #activator="{ props }">
            <v-btn
              v-if="alert.guruCardUrl"
              v-bind="props"
              color="blue"
              variant="text"
              :href="alert.guruCardUrl"
              target="_blank"
              @click.stop="
                logAnalyticsEvent($analytics, `${sidecar ? 'sidecar' : 'dashboard'}_diagnostics_links`, {
                  action: 'Click Guru link',
                  category: `Alert: ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                  label: `Click Guru link in ${sidecar ? 'Sidecar' : 'Dashboard'}`,
                })
              "
            >
              <span v-if="!sidecar">GURU Card</span>
              <span v-else>
                <v-icon v-bind="props" size="large">mdi-alpha-g-box-outline</v-icon>
              </span>
            </v-btn>
          </template>
          {{ alert.guruCardUrl ? alert.guruCardUrl : 'Guru card' }}
        </v-tooltip>
        <v-tooltip v-if="alert.isHidden" location="top">
          <template #activator="{ props }">
            <v-icon v-bind="props" size="large" color="info" @click="togglePin()">
              {{ pinned ? 'mdi-ghost-off-outline' : 'mdi-ghost-outline' }}
            </v-icon>
          </template>
          {{ pinned ? 'Unpin hidden diagnostic (in testing)' : 'Pin hidden diagnostic (in testing)' }}
        </v-tooltip>
        <v-tooltip v-else location="top">
          <template #activator="{ props }">
            <v-icon v-bind="props" size="large" color="info" @click="togglePin()">
              {{ pinned ? 'mdi-pin' : 'mdi-pin-outline' }}
            </v-icon>
          </template>
          {{ pinned ? 'Unpin diagnostic' : 'Pin diagnostic' }}
        </v-tooltip>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { DateTime } from '#mixins/dateTime'

  import { DiagnosticsStoreV3, SidecarStore } from '#stores'

  import { Diagnostic } from '#types'

  @Component
  class DiagnosticAlert extends mixins(DateTime) {
    @Prop() public alert!: Diagnostic
    @Prop() public index!: number
    @Prop() public sidecar!: boolean
    @Prop() public extraInfo!: any
    @Prop() public diagnosticId!: string
    @Prop() public pinned!: boolean
    @Prop() public showHiddenDiagnostics!: boolean

    public showAlert = true

    public expand = false

    public alertMacro = { title: '', id: '' }

    public logAnalyticsEvent = logEvent

    public diagnosticsStore = new DiagnosticsStoreV3()
    public SidecarStore = new SidecarStore()

    public get alertSymbol() {
      // Other status types have corresponding vuetify alert type, but for "not_enough_data" we have to make our own
      if (this.alert?.status === 'not_enough_data') {
        return {
          icon: 'mdi-information',
          color: 'grey-lighten-1',
        }
      }

      return {
        type: this.alert?.status,
      }
    }

    @Emit('update:extraInfo')
    public updateExtraInfo(index: null | number) {
      return index
    }

    @Emit('togglePin')
    public togglePin() {
      return this.diagnosticId
    }

    @Watch('showHiddenDiagnostics', { immediate: true })
    protected onShowHiddenDiagnostics(val: boolean) {
      if (this.alert.isHidden) {
        this.showAlert = val
      }
    }

    public applyMacro(id: string) {
      this.$zafClient.invoke('macro', parseInt(id))
      this.$zafClient.on('ticket.submit.start', () => {
        this.addTagTracker()
      })
    }
    public addTagTracker() {
      this.$zafClient.invoke('ticket.tags.add', [this.alert.macroId, 'zaf_macro'])
    }

    public mounted() {
      if (this.$zafClient) {
        const macroArray = this.SidecarStore.diagnosticMacros
        const zdTags = this.SidecarStore.diagnosticTags
        const locale = this.SidecarStore.diagnosticLocale
        const macro = macroArray.find(
          (s: any) =>
            s.description.includes(this.alert.macroId + '_' + locale) && !zdTags?.includes(this.alert.macroId),
        )
        if (macro) {
          this.alertMacro.title = macro.title
          this.alertMacro.id = macro.id
        }
      }
    }
  }

  export default toNative(DiagnosticAlert)
</script>

<style lang="scss" scoped>
  :deep(.v-input.v-text-field) {
    color: #757575 !important;
  }
  :deep(.v-alert.v-alert--border.v-alert--border-start) {
    border: 0 !important;
    box-shadow:
      0 3px 1px -2px #0003,
      0 2px 2px #00000024,
      0 1px 5px #0000001f !important;
  }

  :deep(.v-alert__prepend) {
    margin-inline-end: -2px;
  }

  :deep(.action) {
    display: inline-block;
    transition: opacity 200ms;
    &.comment {
      color: #2196f3;
    }
    &.positive {
      color: #4caf50;
    }
    &.negative {
      color: #f44336;
    }
  }

  :deep(.details-button) {
    color: #2196f3;
    font-size: 0.9rem;
    cursor: pointer;
  }

  :deep(.sidecar) {
    // text-align: left !important;
    .v-btn--icon.v-btn--density-default {
      width: calc(var(--v-btn-height) + 0px);
      height: calc(var(--v-btn-height) + 0px);
    }
  }
  :deep(.sidecar_left) {
    float: left;
    padding-left: 0px;
  }
  :deep(.v-btn.v-icon) {
    --v-icon-size-multiplier: 1;
  }

  :deep(#extPanContent > *) {
    padding: 0;
  }
  :deep(.expPanel > .v-expansion-panel-content__wrap) {
    padding: 0;
  }
  :deep(.v-btn:not(.v-btn--round).v-size--default) {
    min-width: 25px;
    padding: 3px;
  }

  .v-btn--icon.v-btn--density-default {
    width: calc(var(--v-btn-height) + 0px);
    height: calc(var(--v-btn-height) + 0px);
    padding-right: 30px;
  }

  .alert-text {
    color: rgb(var(--v-theme-on-surface)) !important;
  }
</style>
