import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'

import { Diagnostics } from '#types'

@Store()
export class DiagnosticsStore extends BaseStore {
  public dataWait = false

  public signupFlowDiagnostics: Diagnostics | null = null

  public async getSignupFlowDiagnostics(uuid: string) {
    this.signupFlowDiagnostics = null
    this.dataWait = true

    this.makeRequest(
      { method: 'get', url: `/api/v1/users/${uuid}/diagnostics/signup-flow` },
      'getSignupFlowDiagnostics',
    )
      .then((value: AxiosResponse | null) => {
        this.signupFlowDiagnostics = value?.data
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }
}
