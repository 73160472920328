import dayjs from 'dayjs'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { BaseStore } from '#stores/base'
import { UserStore } from '#stores/user'

import { Nullable, SummaryDataResponse } from '#types'

const REQUEST_SOURCE_GET_SUMMARY_DATA = 'getSummaryDataV2'

@Store()
export class SummaryStore extends BaseStore {
  public summaryDataResponse: Nullable<SummaryDataResponse> = null

  public waitingForSummaryData() {
    return this.waitingForData([REQUEST_SOURCE_GET_SUMMARY_DATA])
  }

  public get summaryDataError() {
    return this.getRequestError(REQUEST_SOURCE_GET_SUMMARY_DATA)
  }

  public async getSummaryData({ userUuid, from, to }: { userUuid: string; from: string; to: string }) {
    this.summaryDataResponse = null
    this.resetRequestError(REQUEST_SOURCE_GET_SUMMARY_DATA)
    const userStore = new UserStore()
    const [dateTo, dateFrom] = [to, from].map((date) => dayjs(date).format('YYYY-MM-DD'))

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${userUuid}/summary-data`, params: { dateTo, dateFrom } },
      REQUEST_SOURCE_GET_SUMMARY_DATA,
      userUuid,
      userStore.user?.uuid,
    )
      .then((response: AxiosResponse | null) => {
        if (response?.data?.summaryData) {
          this.summaryDataResponse = response.data
        }
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_GET_SUMMARY_DATA)
      })
  }
}
