import { UserStore } from './user'

import { AxiosError, AxiosResponse } from 'axios'

import { Store } from 'pinia-class-component'

import { extractContactDetailsFromResponse, extractPaymentMethodsFromResponse } from '#utils/subscription/extract'
import { extractSimilarActionsFromSubscriptionHistoryEvents } from '#utils/subscription/extract.similarActions'

import { BaseStore } from '#stores/base'

import {
  AddressData,
  CompensateSubscriptionBaseRequest,
  CompensateSubscriptionWithEscalationReasonRequest,
  ContactDetails,
  ContactType,
  CreditMemo,
  Invoice,
  Nullable,
  OuraSubscriptionCompensateAction,
  OuraSubscriptionCompensateActionType,
  PaymentMethod,
  PaymentsInfo,
  SubscriptionActionParams,
  SubscriptionAuditLog,
  SubscriptionHistoryEvent,
  Subscriptions,
  UpgradeSubscriptionActionData,
} from '#types'

const REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION = 'upgradeSubscriptionAction'
const REQUEST_SOURCE_START_TRIAL_ACTION = 'startTrialAction'

const REQUEST_SOURCE_START_TRIAL = 'startTrial'
const REQUEST_SOURCE_UPGRADE_SUBSCRIPTION = 'upgradeSubscription'
const REQUEST_SOURCE_RESET_DUNNING = 'resetDunning'
const REQUEST_SOURCE_UPDATE_ADDRESS = 'updateAddress'
const REQUEST_SOURCE_RESET_SUBSCRIPTION = 'resetSubscription'
const REQUEST_SOURCE_CANCEL_SUBSCRIPTION = 'cancelSubscription'
const REQUEST_SOURCE_EXTEND_SUBSCRIPTION = 'extendSubscription'
const REQUEST_SOURCE_GET_CONTACT_DETAILS = 'getContactDetails'
const REQUEST_SOURCE_COMPENSATE_SUBSCRIPTION = 'compensateSubscription'

@Store()
export class SubscriptionStore extends BaseStore {
  public dataWait = false
  public invoices: Invoice[] = []
  public payments: PaymentsInfo[] = []
  public creditMemos: CreditMemo[] = []

  public invoice: Blob | null = null
  public creditMemo: Blob | null = null

  public subscriptions: Subscriptions = {
    subscriptions: [],
    isPaymentMethodSet: false,
    dunningLevel: 0,
    defaultPaymentMethodCardExpired: false,
  }

  public paymentMethods: PaymentMethod[] = []
  public contactDetails: ContactDetails = {
    billToContact: null,
    shipToContact: null,
  }
  public subscriptionAuditLogs: SubscriptionAuditLog[] = []
  public subscriptionHistoryEvents: SubscriptionHistoryEvent[] = []

  public compensationAlreadyGivenMessage: Nullable<string> = null
  public trialAlreadyExtendedMessage: Nullable<string> = null

  private getRequestSourceForActionType(actionType: string): Nullable<string> {
    if (actionType === 'compensateSubscription') {
      return REQUEST_SOURCE_COMPENSATE_SUBSCRIPTION
    } else if (actionType === 'extendSubscription') {
      return REQUEST_SOURCE_EXTEND_SUBSCRIPTION
    }
    return null
  }

  public waitingForStartTrialData(): boolean {
    return this.waitingForData([REQUEST_SOURCE_START_TRIAL_ACTION])
  }

  public waitingForUpgradeLifetimeData(): boolean {
    return this.waitingForData([REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION])
  }

  public waitingForResetDunning(): boolean {
    return this.waitingForData([REQUEST_SOURCE_RESET_DUNNING])
  }

  public waitingForResetSubscription(): boolean {
    return this.waitingForData([REQUEST_SOURCE_RESET_SUBSCRIPTION])
  }

  public waitingForGetContacts(): boolean {
    return this.waitingForData([REQUEST_SOURCE_GET_CONTACT_DETAILS])
  }

  public waitingForAddressUpdate(): boolean {
    return this.waitingForData([REQUEST_SOURCE_UPDATE_ADDRESS])
  }

  public waitingForCompensateAction(actionType: OuraSubscriptionCompensateActionType): boolean {
    const requestSource = this.getRequestSourceForActionType(actionType)
    if (requestSource) {
      return this.waitingForData([requestSource])
    }
    return false
  }

  public get contactDetailsError() {
    return this.getRequestError(REQUEST_SOURCE_GET_CONTACT_DETAILS)
  }

  public getCompensateActionError(actionType: OuraSubscriptionCompensateActionType) {
    const requestSource = this.getRequestSourceForActionType(actionType)
    if (requestSource) {
      return this.getRequestError(requestSource)
    }
    return null
  }

  public resetCompensateError() {
    return this.resetRequestError(REQUEST_SOURCE_COMPENSATE_SUBSCRIPTION)
  }

  public resetExtendSubscriptionError() {
    return this.resetRequestError(REQUEST_SOURCE_EXTEND_SUBSCRIPTION)
  }

  public async getPayments(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    if (data.initial) {
      this.payments = []
      this.dataWait = true
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/payments` },
      'getPayments',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.payments = response?.data?.payments || []

    if (data.initial) {
      this.dataWait = false
    }
  }

  public async getPaymentMethods(data: { userId: string }) {
    const userStore = new UserStore()

    this.paymentMethods = []
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/payment-methods` },
      'getPaymentMethods',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data) {
      this.paymentMethods = extractPaymentMethodsFromResponse(response?.data)
    }
    this.dataWait = false
  }

  public async removePaymentMethod(data: { userId: string; paymentMethodId: string }) {
    const userStore = new UserStore()

    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'delete', url: `/api/v1/users/${data.userId}/payment-methods/${data.paymentMethodId}` },
      'removePaymentMethod',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })
    this.dataWait = false
    await this.getPaymentMethods(data)
    return response?.status
  }

  public async refundPayment(data: {
    refundReasonDetail: string
    paymentId: string
    refundReason: string
    issueStartDate: string
    userId: any
  }) {
    const userStore = new UserStore()

    this.dataWait = true
    // Request data should be typed
    const payload = data.refundReasonDetail
      ? {
          refundReason: data.refundReason,
          issueStartDate: data.issueStartDate,
          refundReasonDetail: data.refundReasonDetail,
        }
      : { refundReason: data.refundReason, issueStartDate: data.issueStartDate }

    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/payments/${data.paymentId}/refund`, data: payload },
      'refundPayment',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.dataWait = false
    return response
  }

  public async getSubscriptions(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.subscriptions = { ...this.subscriptions, subscriptions: [], isPaymentMethodSet: false }
    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription` },
      'getSubscriptions',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    if (response?.data) {
      this.subscriptions = response?.data
    }
    this.dataWait = false
  }

  public async cancelSubscription(data: {
    userId: string
    subscriptionId: string
    cancelImmediately: boolean
  }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()

    this.dataWait = true

    let cancelPayload = {}

    const cancellationReason = 'CX_CANCELLED' // hard-coded for now

    if (data.cancelImmediately) {
      cancelPayload = {
        cancelImmediately: data.cancelImmediately,
        cancellationReason: cancellationReason,
      }
    }

    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/cancel`,
        data: cancelPayload,
      },
      'cancelSubscription',
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_CANCEL_SUBSCRIPTION)
      return null
    })
    this.dataWait = false
    return response
  }

  public async extendSubscription(data: {
    userId: string
    subscriptionId: string
    escalationReason: string
    issueStartDate: string
    extensionReason: string
    extensionMonths: number
  }) {
    const userStore = new UserStore()
    this.resetExtendSubscriptionError()

    // Request data should be typed
    const payload = {
      extendMonths: Number(data.extensionMonths),
      extendReason: data.extensionReason,
      escalationReason: data.escalationReason,
      issueStartDate: data.issueStartDate,
    }

    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/extend-trial`,
        data: payload,
      },
      REQUEST_SOURCE_EXTEND_SUBSCRIPTION,
      data.userId,
      userStore.user?.uuid,
    )
      .then((_response: AxiosResponse | null) => {
        // we probably should not block this action by refreshing the subscriptions here, but trigger it
        // on the Vue component side
        this.getSubscriptions({ userId: data.userId })
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_EXTEND_SUBSCRIPTION)
      })
  }

  public async compensateAction(data: OuraSubscriptionCompensateAction) {
    if (data.actionType === 'compensateSubscription') {
      if (data.escalationReason) {
        const payload: CompensateSubscriptionWithEscalationReasonRequest = {
          compensationMonths: Number(data.compensationMonths),
          reason: data.reason,
          issueStartDate: data.issueStartDate,
          escalationReason: data.escalationReason,
        }
        return await this.compensateSubscription({
          payload: payload,
          memberId: data.memberId,
          subscriptionId: data.subscriptionId,
        })
      } else {
        const payload: CompensateSubscriptionBaseRequest = {
          compensationMonths: Number(data.compensationMonths),
          reason: data.reason,
          issueStartDate: data.issueStartDate,
        }
        return await this.compensateSubscription({
          payload: payload,
          memberId: data.memberId,
          subscriptionId: data.subscriptionId,
        })
      }
    } else if (data.actionType === 'extendSubscription') {
      return await this.extendSubscription({
        userId: data.memberId,
        subscriptionId: data.subscriptionId,
        extensionReason: data.reason,
        escalationReason: data.escalationReason,
        issueStartDate: data.issueStartDate,
        extensionMonths: Number(data.compensationMonths),
      })
    }
  }

  private async compensateSubscription(data: {
    payload: CompensateSubscriptionBaseRequest | CompensateSubscriptionWithEscalationReasonRequest
    memberId: string
    subscriptionId: string
  }) {
    const userStore = new UserStore()
    this.resetCompensateError()
    await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.memberId}/subscription/${data.subscriptionId}/compensate`,
        data: data.payload,
      },
      REQUEST_SOURCE_COMPENSATE_SUBSCRIPTION,
      data.memberId,
      userStore.user?.uuid,
    )
      .then((_response: AxiosResponse | null) => {
        // we probably should not block this action by refreshing the subscriptions here, but trigger it
        // on the Vue component side
        this.getSubscriptions({ userId: data.memberId })
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_COMPENSATE_SUBSCRIPTION)
      })
  }

  public async upgradeSubscription(data: UpgradeSubscriptionActionData): Promise<AxiosResponse | null> {
    // this action datawait could be probably removed by adding the getSubscriptions() inside .then() block
    this.updateDataWait({ source: REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION, wait: true })
    const userStore = new UserStore()

    // Upgrade lifetime request doesn't require shipping details - in that case body is empty
    const requestData: any = {}
    if (data.shippingDetails) {
      requestData['shipToContact'] = data.shippingDetails
    }

    const upgradeSubscriptionUrlParams: SubscriptionActionParams = { validateAddress: null }
    if (data.validateAddress) {
      upgradeSubscriptionUrlParams.validateAddress = true
    }

    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/subscription/${data.subscriptionId}/upgrade-lifetime`,
        params: upgradeSubscriptionUrlParams,
        data: requestData,
      },
      REQUEST_SOURCE_UPGRADE_SUBSCRIPTION,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_UPGRADE_SUBSCRIPTION)
    })
    await this.getSubscriptions(data)
    this.updateDataWait({ source: REQUEST_SOURCE_UPGRADE_SUBSCRIPTION_ACTION, wait: false })

    if (!response) {
      return null
    }
    return response
  }

  public async getSubscriptionAuditLogs(data: { initial?: boolean; userId: string }) {
    const userStore = new UserStore()

    if (data.initial) {
      this.dataWait = true
      this.subscriptionAuditLogs = []
    }

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription/audit-events` },
      'getSubscriptionAuditLogs',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.subscriptionAuditLogs = response?.data || []
    if (data.initial) {
      this.dataWait = false
    }
  }

  public async getSubscriptionHistoryEvents(data: { userId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.subscriptionHistoryEvents = []

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/subscription/history-events` },
      'getSubscriptionHistoryEvents',
      data.userId,
      userStore.user?.uuid,
    )
      .then((response: AxiosResponse | null) => {
        this.subscriptionHistoryEvents = response?.data?.historyEvents || []
        const similarActions = extractSimilarActionsFromSubscriptionHistoryEvents(this.subscriptionHistoryEvents)
        this.compensationAlreadyGivenMessage = similarActions.compensationAlreadyGivenMessage
        this.trialAlreadyExtendedMessage = similarActions.trialAlreadyExtendedMessage
      })
      .catch((_axiosError: AxiosError) => {
        // ignoring for now, should be handled properly
        return null
      })

    this.dataWait = false
  }

  public async getInvoices(data: { userId: string }) {
    const userStore = new UserStore()

    this.invoices = []
    this.dataWait = true

    const response = await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/invoices` },
      'getInvoices',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.invoices = response?.data?.invoices || []
    this.dataWait = false
  }

  public async getInvoice(data: { userId: string; invoiceId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.invoice = null

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/invoices/${data.invoiceId}`,
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      },
      'getInvoice',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.invoice = response?.data || null
    this.dataWait = false
  }

  public async getCreditMemos(data: { userId: string }) {
    const userStore = new UserStore()

    this.creditMemos = []
    this.dataWait = true

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/credit-memos`,
      },
      'getCreditMemos',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.creditMemos = response?.data?.creditMemos || []
    this.dataWait = false
  }

  public async getCreditMemo(data: { userId: string; memoId: string }) {
    const userStore = new UserStore()

    this.dataWait = true
    this.creditMemo = null

    const response = await this.makeRequest(
      {
        method: 'get',
        url: `/api/v1/users/${data.userId}/credit-memos/${data.memoId}`,
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      },
      'getCreditMemo',
      data.userId,
      userStore.user?.uuid,
    ).catch((_axiosError: AxiosError) => {
      // ignoring for now, should be handled properly
      return null
    })

    this.creditMemo = response?.data || null
    this.dataWait = false
  }

  public async resetSubscriptions(data: { userId: string }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/subscription/reset` },
      REQUEST_SOURCE_RESET_SUBSCRIPTION,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_RESET_SUBSCRIPTION)
    })
    if (!response) {
      return null
    }
    return response
  }

  public async resetDunning(data: { userId: string }): Promise<AxiosResponse | null> {
    const userStore = new UserStore()
    const response = await this.makeRequest(
      { method: 'post', url: `/api/v1/users/${data.userId}/subscription/reset-dunning-history` },
      REQUEST_SOURCE_RESET_DUNNING,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_RESET_DUNNING)
    })
    if (!response) {
      return null
    }
    return response
  }

  public async getContactDetails(data: { userId: string }) {
    const userStore = new UserStore()
    this.resetRequestError(REQUEST_SOURCE_GET_CONTACT_DETAILS)

    await this.makeRequest(
      { method: 'get', url: `/api/v1/users/${data.userId}/contact-details` },
      REQUEST_SOURCE_GET_CONTACT_DETAILS,
      data.userId,
      userStore.user?.uuid,
    )
      .then((value: AxiosResponse | null) => {
        if (value) {
          this.contactDetails = extractContactDetailsFromResponse(value.data)
        }
      })
      .catch((axiosError: AxiosError) => {
        this.handleRequestError(axiosError, REQUEST_SOURCE_GET_CONTACT_DETAILS)
      })
  }

  public async startTrial(data: AddressData): Promise<AxiosResponse | null> {
    // this action datawait could be probably removed by adding the getSubscriptions() inside .then() block
    this.updateDataWait({ source: REQUEST_SOURCE_START_TRIAL_ACTION, wait: true })
    const userStore = new UserStore()

    const response = await this.makeRequest(
      {
        method: 'post',
        url: `/api/v1/users/${data.userId}/subscription/start-trial`,
        params: { validateAddress: data.validateAddress },
        data: { shipToContact: data.shippingDetails },
      },
      REQUEST_SOURCE_START_TRIAL,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_START_TRIAL)
    })
    await this.getSubscriptions({ initial: false, userId: data.userId })
    this.updateDataWait({ source: REQUEST_SOURCE_START_TRIAL_ACTION, wait: false })
    if (!response) {
      return null
    }
    return response
  }

  public async updateAddress(data: AddressData, type: ContactType): Promise<AxiosResponse | null> {
    const requestData =
      type === 'shipToContact' ? { shipToContact: data.shippingDetails } : { billToContact: data.shippingDetails }

    this.updateDataWait({ source: REQUEST_SOURCE_UPDATE_ADDRESS, wait: true })
    const userStore = new UserStore()
    const response = await this.makeRequest(
      {
        method: 'put',
        url: `/api/v1/users/${data.userId}/contact-details`,
        params: { validateAddress: data.validateAddress },
        data: requestData,
      },
      REQUEST_SOURCE_UPDATE_ADDRESS,
      data.userId,
      userStore.user?.uuid,
    ).catch((axiosError: AxiosError) => {
      this.handleRequestError(axiosError, REQUEST_SOURCE_UPDATE_ADDRESS)
    })

    this.updateDataWait({ source: REQUEST_SOURCE_UPDATE_ADDRESS, wait: false })

    if (!response) {
      return null
    }
    return response
  }
}
