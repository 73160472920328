<template>
  <div>
    <v-dialog
      v-if="config"
      v-model="isOpen"
      width="600"
      class="backdrop"
      @click:outside="closeDialog('click-outside')"
      @keydown="closeDialog($event.keyCode)"
    >
      <v-card>
        <v-card-title class="primary">
          <span class="white--text text-heading-5">{{ config.title }}</span>
        </v-card-title>

        <v-card-text class="px-6 pb-0">
          <v-progress-circular
            v-show="dataWait"
            indeterminate
            size="70"
            width="8"
            style="position: absolute; top: 50%; left: 45%"
          />

          <v-row ref="notification">
            <div v-if="notificationText" style="position: sticky; top: 60px; width: 100%">
              <v-sheet min-height="50">
                <v-alert :type="notificationType">
                  <div>
                    {{ notificationText }}
                  </div>
                </v-alert>
              </v-sheet>
            </div>
          </v-row>
          <p class="mb-0 mt-3 text-subtitle-1">
            {{ config.description }}
          </p>

          <v-select
            id="ouraSubCompensateReason"
            v-model="compensationReason"
            :items="config.reasons"
            :disabled="!inputEnabled('compensationReason')"
            label="Select the reason"
            item-title="text"
            item-value="value"
          />

          <v-select
            id="ouraSubCompensateOption"
            v-model="compensationMonths"
            :items="config.compensationOptions"
            :disabled="!inputEnabled('compensationMonths')"
            :item-value="compensationMonths"
            :label="config.compensationOptionsTitle"
            item-text="text"
          />

          <v-date-input
            validate-on="lazy input"
            label="Issue start date"
            data-cy="ouraSubCompensateIssueStartDate"
            placeholder="In DD.MM.YYYY format"
            :disabled="!inputEnabled('issueStartDate')"
            :model-value="issueStartDate"
            :rules="[
              (date) => (issueStartDateValid = $dayjs(date, 'DD.MM.YYYY').isValid()),
              (date) =>
                $dayjs(date, 'DD.MM.YYYY').isValid()
                  ? (issueStartDate = $dayjs(date, 'DD.MM.YYYY').format('YYYY-MM-DD')) && true
                  : true,
            ]"
            @update:model-value="issueStartDate = $dayjs($event).format('YYYY-MM-DD')"
          />

          <v-textarea
            v-if="compensationReason === 'Escalations'"
            id="ouraSubCompensateEscalationReason"
            v-model="compensationEscalationReason"
            :disabled="!inputEnabled('compensationEscalationReason')"
            hide-details="auto"
            label="Escalation reason*"
            rows="2"
            :rules="[(v: string) => !!v || 'Escalation reason is required']"
            data-cy="ouraSubCompensateEscalationReason"
          />

          <div v-if="compensationMessage">
            <v-alert type="warning">
              {{ compensationMessage }}
            </v-alert>
            <v-checkbox
              v-model="compensationConfirmed[config.actionType]"
              :disabled="!inputEnabled('confirmAnyway')"
              :label="config.confirmAnywayText"
              data-cy="confirmAnywayCheckbox"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn variant="text" data-cy="ouraSubCompensateCloseButton" @click="close()">
            {{ closeButtonText }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!confirmButtonEnabled"
            variant="text"
            data-cy="ouraSubCompensateSaveButton"
            @click="giveCompensation()"
          >
            Save and confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { SubscriptionStore } from '#stores'

  import { Nullable, OuraSubscriptionCompensateConfig } from '#types'

  @Component
  export class OuraSubscriptionCompensate extends Vue {
    public subscriptionStore = new SubscriptionStore()
    public notificationText: Nullable<string> = null
    public notificationType: 'warning' | 'error' | 'success' | 'info' = 'info'
    public compensationReason = ''
    public compensationEscalationReason = ''
    public compensationMonths = '1'
    public issueStartDate = ''
    public isOpen = false
    public issueStartDateValid = true
    public justSaved = false
    public compensationConfirmed: {
      extendSubscription: boolean
      compensateSubscription: boolean
    } = {
      extendSubscription: false,
      compensateSubscription: false,
    }
    public config: Nullable<OuraSubscriptionCompensateConfig> = null

    declare public $refs: {
      notification: any
    }

    public get dataWait() {
      return this.config?.actionType ? this.subscriptionStore.waitingForCompensateAction(this.config.actionType) : false
    }

    public get compensationAlreadyGivenMessage(): Nullable<string> {
      return this.subscriptionStore.compensationAlreadyGivenMessage
    }

    public get trialAlreadyExtendedMessage(): Nullable<string> {
      return this.subscriptionStore.trialAlreadyExtendedMessage
    }

    public get compensationMessage(): Nullable<string> {
      // Figure out more elegant way to handle multiple messages
      if (this.config) {
        if (this.config.actionType === 'compensateSubscription') {
          return this.compensationAlreadyGivenMessage
        } else if (this.config.actionType === 'extendSubscription') {
          return this.trialAlreadyExtendedMessage
        }
      }
      return null
    }

    @Watch('compensationAlreadyGivenMessage', { immediate: true })
    public compensationAlreadyGivenMessageChanged(message: Nullable<string>): void {
      // Figure out more elegant way to handle multiple messages
      if (message) {
        this.compensationConfirmed.compensateSubscription = false
      } else {
        this.compensationConfirmed.compensateSubscription = true
      }
    }

    @Watch('trialAlreadyExtendedMessage', { immediate: true })
    public trialAlreadyExtendedMessageChanged(message: Nullable<string>): void {
      // Figure out more elegant way to handle multiple messages
      if (message) {
        this.compensationConfirmed.extendSubscription = false
      } else {
        this.compensationConfirmed.extendSubscription = true
      }
    }

    public async open(config: OuraSubscriptionCompensateConfig) {
      this.resetDialog()
      this.config = config
      this.isOpen = true
    }

    public async close() {
      this.isOpen = false
      this.justSaved = false
      this.resetDialog()
    }

    public resetDialog() {
      if (this?.config?.actionType) {
        this.compensationConfirmed[this.config.actionType] = false
      }
      this.compensationReason = ''
      this.compensationEscalationReason = ''
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')
      this.compensationMonths = '1'
      this.notificationText = null
    }

    public get confirmButtonEnabled() {
      if (!this.config || !this.config.actionType) {
        return false
      }
      if (this.compensationReason === 'Escalations') {
        return (
          this.compensationReason &&
          this.compensationEscalationReason &&
          this.compensationMonths &&
          this.issueStartDate &&
          this.isOpen &&
          this.issueStartDateValid &&
          !this.justSaved &&
          !this.dataWait &&
          this.compensationConfirmed[this.config.actionType]
        )
      }
      return (
        this.compensationReason &&
        this.compensationMonths &&
        this.issueStartDate &&
        this.isOpen &&
        this.issueStartDateValid &&
        !this.justSaved &&
        !this.dataWait &&
        this.compensationConfirmed[this.config.actionType]
      )
    }

    public mounted() {
      this.issueStartDate = this.$dayjs().format('YYYY-MM-DD')
    }

    public inputEnabled(_fieldName: string): boolean {
      if (this.justSaved || this.dataWait) {
        return false
      }
      return true
    }

    public get closeButtonText(): string {
      return this.justSaved ? 'Close' : 'Cancel'
    }

    private async logAnalyticsEvent() {
      if (this.config) {
        logEvent(this.$analytics, this.config.analytics.eventName, {
          category: this.config.analytics.category,
          action: 'Click to ' + this.config.analytics.actionType,
          label: 'Click to ' + this.config.analytics.actionType,
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
      }
    }

    private async compensateAction() {
      if (this.config && this.compensationReason) {
        await this.subscriptionStore.compensateAction({
          actionType: this.config.actionType,
          memberId: this.config.memberId,
          subscriptionId: this.config.subscriptionId,
          reason: this.compensationReason,
          escalationReason: this.compensationEscalationReason,
          issueStartDate: this.issueStartDate,
          compensationMonths: Number(this.compensationMonths),
        })
      }
    }

    public async giveCompensation() {
      if (!this.config) {
        throw Error('User attempted to confirm compensation without component configuration. This should never happen.')
      }
      if (this.compensationReason) {
        await this.logAnalyticsEvent()
        this.notificationType = 'info'
        this.notificationText = this.config.pendingNotificationText
        await this.compensateAction()
        const requestError = this.subscriptionStore.getCompensateActionError(this.config.actionType)
        if (requestError) {
          this.notificationType = 'error'
          this.notificationText = requestError.userMessage
        } else {
          this.notificationType = 'success'
          this.notificationText = this.config.successNotificationText
          this.justSaved = true
        }
      }
    }

    /***
     * Handle events from clicking outside v-dialog
     * or closing the dialog by pressing ESC
     * @param event
     */
    public closeDialog(event: string | number) {
      if (event == 'click-outside' || event == 27) {
        this.close()
      }
    }
  }

  export default toNative(OuraSubscriptionCompensate)
</script>
